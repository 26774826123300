import { primary, secondary, successButton, mediumRedTitle, medium, hidden } from '../../core/GlobalStyles'

export default {
  checkBoxIcon: {
    width: '10vmin',
    height: '10vmin',
  },
  ...mediumRedTitle,
  container: {
    display: 'flex',
    height: '95vh',
    width: '90vw',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    width: '40vmin',
    height: '40vmin'
  },
  question: {
    fontSize: '8vmin',
    color: primary,
    fontFamily: 'revalia'
  },
  divLogo:{
    display: 'flex',
    margin: '10vmin 0'
  },
  divInput:{
    display: 'flex'
  },
  validate:{
    ...successButton,
    fontSize: medium,
    display: 'flex',
    flex: '10',
    marginLeft: '5vmin'
  },
  ...hidden,
  form:{
    display: 'flex'
  },


  // INPUT :
  textField:{
    flex: '20'
  },
  cssLabel: {
    color: secondary,
    fontSize: '3vmin',
    fontFamily: 'mogra'
  },
  cssLabelFocused:{
    color: secondary+' !important',
  },
  cssInput:{
    color: secondary,
    fontSize: medium,
    fontFamily: 'mogra'
  },
  outlineOfTheInput:{
    borderColor: secondary+' !important',
  },
}