import { secondary, large } from '../../core/GlobalStyles'

export default {
  textTimer:{
    color: secondary,
    fontFamily: 'revalia',
    fontSize: large
  },
  marginTimer:{
    margin: '5vmin 0'
  }
}