import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import ReactTimeout from 'react-timeout'
import { compose } from 'redux'

import wine from '../../img/icons/wineGlass.svg';
import wineSelected from '../../img/icons/wineGlassFull.svg';
import loading from '../../img/loading.gif';
import QuestionStyles from './QuestionStyles';
import TimerView from '../timer/TimerView';
import Utils from '../../utilsES6';

function mapStateToProps(state) {
  return {
    game: state.room.game,
    isHost: state.me.isHost,
    socket: state.me.socket,
    isProduction: state.isProduction
  };
}

class QuestionView extends Component {
  constructor(props){
    super(props);
    
    const { shuffle } = Utils
    let reponses = this.props.game ? this.props.game.reponses : [];
    shuffle(reponses);

    this.state = {
      selected: '',
      isTitle: true,
      isLocked: false,
      reponses: reponses
    }
  }

  componentDidMount = () => {
    this.props.setTimeout(() => {
      this.setState({isTitle: false});
    }, 3500);
  }

  componentDidUpdate(prevProps){
    //Reset les réponses avec la nouvelle question ! 
    if(this.props.game.id !== prevProps.game.id){
      const { shuffle } = Utils
      let r = this.props.game ? this.props.game.reponses : [];
      shuffle(r);
      this.setState({reponses: r});
    }
  }

  lockAnswer = () => {
    this.setState({isLocked: true});
    this.props.socket.emit('question.lockAnswer', this.state.selected);
  }

  handleChange = (e) => {
    this.setState({selected: e.target.value});
  }

  handleEnd = () => {
    this.props.socket.emit('endOfQuestion');
  }

  renderHost = () => {
    const { game, classes, isProduction } = this.props;
    const { isTitle } = this.state;

    if(isTitle){
      return <div className={classes.question}>
                - QCM - 
                <br/>
                Choisissez la bonne réponse
             </div>
    }

    return  <div>
              { isProduction && 
                <TimerView 
                  time={game.time*1000}
                  endOfTimer={this.handleEnd}
                /> 
              }
            <div
              className={classes.question}
              dangerouslySetInnerHTML={{__html: game.question}}>
            </div>
          </div>
  }

  renderPlayer = () => {
    const { classes } = this.props;
    const { reponses } = this.state;
    const { isLocked, selected, isTitle } = this.state;

    if(isLocked || isTitle){
      return <div>
              <img src={loading} alt='Loading gif'/>
            </div>
    } else {
      return <div className={classes.container}>
                <div className={classes.reponses}>
                  {
                    reponses.map((r, index) => {
                      return  <div key={index} className={classes.rep}>
                                <FormControlLabel
                                  label={<span className={classes.repTexte}>{r.texte}</span>}
                                  control={
                                    <Checkbox
                                      icon={<img 
                                              className={classes.checkBoxIcon} 
                                              src={wine} 
                                              alt="select"
                                            />}
                                      checkedIcon={<img 
                                              className={classes.checkBoxIcon} 
                                              src={wineSelected} 
                                              alt="selected"
                                            />}
                                      value={`${r.id}`} //met en string
                                      checked={selected === r.id.toString()}
                                      onChange={this.handleChange}
                                    />
                                  }
                                />
                              </div>
                    })
                  }
                </div>
                <Button
                  variant="contained"
                  className={classnames(classes.validate, selected === "" && classes.hidden)}
                  onClick={this.lockAnswer}>
                  Valider
                </Button>
            </div>
    }
  }

  render() {
    const { isHost } = this.props;
    return (
      <div>
        {
          isHost ? this.renderHost() : this.renderPlayer()
        }
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(QuestionStyles),
  ReactTimeout
)(QuestionView);
