import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import bouteille from '../../img/bouteille.svg';
import bouteille2 from '../../img/bouteille2.svg';
import PlayerListStyles from './PlayerListStyles';

function mapStateToProps(state) {
  return {
    playerList: state.room.playerList
  };
}

class PlayerListView extends Component {

  render() {
    const { playerList, classes } = this.props;
    return (
      <div className={classes.list}>
        {
          playerList.map((player, index) => {
              return  <div
                        key={index} 
                        className={classes.player}>
                        <img 
                          key={index}
                          src={player.isReady ? bouteille2 : bouteille}
                          className={classes.playerImage}
                          alt='bottle'/>
                        <div className={classes.playerName}>
                          {player.name}
                        </div>
                      </div>
          })
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(PlayerListStyles)(PlayerListView));
