import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import SVG from 'react-inlinesvg';
import { compose } from 'redux'
import ReactTimeout from 'react-timeout'

import battery from '../../img/icons/battery.svg';
import loading from '../../img/loading.gif';
import Utils from '../../utilsES6';

import BatteryDuelStyles from './BatteryDuelStyles';

function mapStateToProps(state) {
  return {
    game: state.room.game,
    isHost: state.me.isHost,
    socket: state.me.socket,
    playerList: state.room.playerList
  };
}

let t, p, batterySuccess, batteryFailure;

class BatteryDuelView extends Component {
  constructor(props){
    super(props);
    const { socket = {} } = this.props

    this.state = {
      isTitle: true,
      isPrintValues: false,
      levels: []
    }

    // Events reçus depuis socket.io
    socket.on('batteryDuel.levels', this.setLevels);
  }

  componentWillUnmount(){
    const { socket = {} } = this.props
    socket.removeAllListeners("batteryDuel.levels");
    this.props.clearTimeout(t);
    t = null;
    p = null;
    batterySuccess = null;
    batteryFailure = null;
  }

  componentDidMount = () => {
    const { isHost, socket } = this.props;

    if(!isHost){
      p = (navigator && typeof navigator.getBattery === 'function') ? navigator.getBattery() : false;
      if(p && typeof p.then === 'function'){

        batterySuccess = (batteryManager) => {
          const level = batteryManager.level;
          socket.emit('batteryDuel.myLevel', level);
        }
        
        batteryFailure = () => {
          console.error('On ne peut pas lire le niveau de batterie de cet appareil');
        }

        p.then(batterySuccess, batteryFailure);
      } else {
        const bat = navigator.battery || navigator.webkitBattery || navigator.mozBattery || navigator.msBattery || {level: -1};
        const level = (bat && !isNaN(bat.level)) ? bat.level : -1;
        socket.emit('batteryDuel.myLevel', level);
      }
    } else {

      //TIMELINE du duel de batteries :
      t = this.props.setTimeout(() => {
        this.props.clearTimeout(t);
        t = null;
        this.setState({isTitle: false});
        this.props.setTimeout(() => {
          this.props.clearTimeout(t);
          t = null;
          this.setState({isPrintValues: true});
          this.props.setTimeout(() => {
            this.props.clearTimeout(t);
            t = null;
            // On renvoie le tableau levels modifié avec les hasWon flags sur les gagnants !
            socket.emit('batteryDuel.results', this.state.levels);
          }, 5100); // Passe au jeu suivant
        }, 1700); // Affiche les levels
      }, 3200); // isTitle
    }
  }

  setLevels = (levels) => {
    const maxLevel = Math.max.apply(Math, levels.map((l) => (l && !isNaN(l.value)) ? l.value : 0));
    levels.forEach(level => {
      level.hasWon = level ? (level.value === maxLevel) : false;
    });
    
    // Demande le print des niveaux de batteries !
    this.setState({levels: levels});
  }

  renderHost = () => {
    const { classes, playerList } = this.props;
    const { isTitle, isPrintValues, levels } = this.state;
    const { getById } = Utils;

    if(isTitle){
      return <div className={classes.question}>Duel de batterie ...</div>
    }

    return <div className={classes.batteryContainer}>
            {
              playerList.map((player, index) => {
                let level = getById(levels, player.id);

                //Fake level object with -1
                level = level ? level : {value: -1};
                
                const levelPercent = Math.round(level.value*100);
                let levelPercentTxt = levelPercent + ' %';
                
                const classToAdd = isPrintValues ? (level.hasWon ? classes.winner : classes.looser ) : ''; 
                
                let hsl = levelPercent - 10;
                const maxHeight = 9;
                const minHeight = 0.8;
                let height = isPrintValues ? ((maxHeight * levelPercent / 100) + minHeight) : 0;

                if(level.value === -1){
                  hsl = 0;
                  height = 0;
                  levelPercentTxt = "???";
                }

                return (
                        <div 
                          className={classes.playerBat}
                          key={index}
                        >
                          <SVG
                            src={battery}
                            className={classes.batteryIcon}>
                          </SVG>
                          <span className={classes.remplissage}
                                dangerouslySetInnerHTML={{__html: " " }}
                                style={{backgroundColor: 'hsl('+hsl+', 70%, 50%)',
                                        height: height+'vmin',
                                        bottom: (height+0.8)+'vmin'}}>
                            
                          </span>

                          {isPrintValues ? levelPercentTxt : ''}

                          <span className={classToAdd}>
                            {player.name}
                          </span>
                        </div>)
              })
            }
          </div>
  }

  renderPlayer = () => {
    return (
        <div>
          <img src={loading} alt='Loading gif'/>
        </div>
          );
  }

  render() {
    const { isHost } = this.props;
    return isHost ? this.renderHost() : this.renderPlayer();
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(BatteryDuelStyles),
  ReactTimeout
)(BatteryDuelView);
