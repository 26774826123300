import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ReactTimeout from 'react-timeout'
import { compose } from 'redux'

import loading from '../../img/loading.gif';
import FragmentationStyles from './FragmentationStyles';
import TimerView from '../timer/TimerView';
import Utils from '../../utilsES6';

function mapStateToProps(state) {
  return {
    game: state.room.game,
    isHost: state.me.isHost,
    socket: state.me.socket,
    playerList: state.room.playerList,
    isProduction: state.isProduction
  };
}

const { rand } = Utils;
let t;
let _NB_STEP = 8;
const hslInit = rand(0, 361);
const _COLORS = [
                  `hsl(${hslInit}, 75%, 50%)`,
                  `hsl(${hslInit+50}, 75%, 50%)`,
                  `hsl(${hslInit+100}, 75%, 50%)`,
                  `hsl(${hslInit+150}, 75%, 50%)`,
                  `hsl(${hslInit+200}, 75%, 50%)`
                ];
const _DELTAX = 3;
const _DELTAY = 3;

class FragmentationView extends Component {
  constructor(props){
    super(props);

    const { socket = {}, isHost } = this.props;
    this.canvasRef = React.createRef();

    // Initial state
    this.state = {
      isTitle: true,
      itsOver: false,
      points: 0,
    }

    // Bind socket event
    if(!isHost){
      socket.on('fragmentation.over', this.sendScores);
    }
    
  }

  componentDidMount = () => {
    const { isHost } = this.props;
    if(!isHost){
      this.initTab();
    }
    t = this.props.setTimeout(() => {
      this.props.clearTimeout(t);
      t = null;
      this.setState({isTitle: false});

    }, 3000);
  }

  componentWillUnmount = () => {
    const { socket = {} } = this.props
    socket.removeAllListeners("fragmentation.over");
    this.props.clearTimeout(t);
    t = null;
  }

  sendScores = () => {
    const { socket = {} } = this.props
    const { itsOver, points } = this.state;
    if(!itsOver){
      socket.emit('fragmentation.sendResults', points);
    }
    this.setState({itsOver: true});
  }

  initTab = () => {
    const height = window.innerHeight*96/100;
    const width = window.innerWidth*96/100;
    const initialCanv = {
      canvasHeight: Math.round(height),
      canvasWidth: Math.round(width),
      stepY: Math.round(height/_NB_STEP),
      stepX: Math.round(width/_NB_STEP),
    }
    let tab = [];
    for (let j = 0; j < 8; j++) {
      tab[j] = [];
      for (let i = 0; i < 8; i++) {
        const idCadran1 = (j>=4) ? (i>=4 ? 3 : 2) : (i>=4 ? 1 : 0);
        const idCadran2 = Math.floor(i/2) + Math.floor(j/2)*4;
        tab[j].push({ 
          x: i,
          y: j,
          level: 0,
          idCadran1,
          idCadran2,
          isDeltaXcadran1: i === 3,
          isDeltaYcadran1: j === 3,

          isDeltaXcadran2: i%2 === 1,
          isDeltaYcadran2: j%2 === 1,

          isDeltaXcadran3: true,
          isDeltaYcadran3: true,

          isDeltaXcadran4: true,
          isDeltaYcadran4: true,
        })
      }
    }
    this.setState({tab: tab, ...initialCanv});
    this.drawTab();
  }

  componentDidUpdate = () => {
    const { isHost } = this.props;
    if(!isHost){
      this.drawTab();
    }
  }

  drawTab = () => {
    const {tab, stepX, stepY } = this.state;
    if(tab && this.canvasRef.current && !this.state.itsOver){
      const canvas = this.canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      for (let j = 0; j < 8; j++) {
        for (let i = 0; i < 8; i++) {
          const elem = tab[j][i];
          const level = elem.level;

          const deltaX = elem[`isDeltaXcadran${level}`] ? _DELTAX : 0;
          const deltaY = elem[`isDeltaYcadran${level}`] ? _DELTAY : 0;

          ctx.strokeStyle = 'blue';
          ctx.fillStyle = _COLORS[tab[j][i].level];
          ctx.fillRect(i*stepX, j*stepY , stepX-deltaX, stepY-deltaY);
        }
      }
    }
  }

  handleCanvasClick = (e) => {
    const { tab, stepX, stepY, points } = this.state;
    const x = e.clientX;
    const y = e.clientY;
    let Npoints = points;
    const column = Math.ceil(x/stepX) - 1;
    const line = Math.ceil(y/stepY) - 1;
    const selectedElem = tab[line][column];
    const currentLevel = selectedElem.level;

    if(currentLevel === 0) {
      tab.forEach(line => line.forEach(elem => elem.level++));
    } else if(currentLevel < 3){
      const id = selectedElem[`idCadran${currentLevel}`];
      tab.forEach(line => line.forEach(elem => {
        if(elem[`idCadran${currentLevel}`] === id){
          elem.level++;
        } 
      }));
    } else if (currentLevel === 3){
      selectedElem.level++;
    }

    if(currentLevel < 4){
      Npoints++
    }
    
    this.setState({tab, points: Npoints});
    console.log(Npoints);
  }


  handleEnd = () => {
    const { socket = {} } = this.props
    socket.emit('fragmentation.timesUp');
  }

  renderPlayer = () => {
    const { classes } = this.props;
    const { isTitle, canvasHeight, canvasWidth, itsOver } = this.state;

    if(isTitle || itsOver){
      return <div>
              <img src={loading} alt='Loading gif'/>
            </div>
    } else if(true) {
      return  <div className={classes.container}>
                <canvas
                  className={classes.canvasClass}
                  ref={this.canvasRef}
                  height={canvasHeight}
                  width={canvasWidth}
                  onClick={this.handleCanvasClick}
                />
              </div>
    }
  }

  renderHost = () => {
    const { game, classes } = this.props;
    const { isTitle } = this.state;

    if(isTitle){
      return <div className={classes.mediumRedTitle}>
                - Fragmentation -
                <br/>
                À vos marques, prêt ? ... Fragmentez !
             </div>
    } else if(true){
      return <div className={classes.mediumRedTitle}>
                <TimerView time={game.time*1000} endOfTimer={this.handleEnd} />
            </div>
    }
  }

  render() {
    const { isHost } = this.props;
    return (
      <div>
        {
          isHost ? this.renderHost() : this.renderPlayer()
        }
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(FragmentationStyles),
  ReactTimeout
)(FragmentationView);