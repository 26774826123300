import { mediumRedTitle } from '../../core/GlobalStyles'

export default {
  mediumRedTitleWithSVG:{
    ...mediumRedTitle.mediumRedTitle,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  container: {
    display: 'flex',
    height: '95vh',
    width: '90vw',
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',
  },
  hostPodium:{
    width: '35vmin',
    height: '35vmin',
  },
  badgeSVG:{
    width: '10vmin',
    height: '10vmin',
    marginBottom: '3vmin'
  },
  score: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  scoreBar:{
    backgroundColor: 'red',
    width: '3vw',
    borderRadius: '10px 10px 0px 0px',
    transition: '4s'
  },
  scorePoints:{
    padding: '2vmin 0'
  }
}