/**    
 *
 *   Reducer : MaJ du state de l'application
 *    - trigger par les Actions
 *  
 */
import { adminPlayerSelector } from '../game/GameSelectors';

export const INITIAL_STATE = {
  me: {
    name: '',
    socket: null,
    isAdmin: false,
    isHost: false,
  },
  room: {
    id: '', // Trigramme
    host:'', // Id du socket Host
    isLocked: false,
    playerList: [],
    game: {
      type: 'waiting'
    }
  },
  isProduction: process.env.NODE_ENV === "production"
};

export default function Reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_USER_FIELDS':
      return  { 
                ...state,
                me: {
                      ...state.me,
                      ...action.payload
                    }
              };
    case 'UPDATE_PLAYER_LIST':
      let isAdmin = false;
      const admin = adminPlayerSelector(action.payload);
      const currentId = state.me.socket ? state.me.socket.id : '';
      if(admin && admin.id === currentId){
        isAdmin = true;
      }
      return  { 
                ...state,
                me: {
                  ...state.me,
                  isAdmin: isAdmin
                },
                room: {
                  ...state.room,
                  playerList: action.payload
                }
              };
    case 'SET_ROOM_DATA':
      const isHost = ( action.payload.host === state.me.socket.id );
      return  { 
                ...state,
                me: {
                  ...state.me,
                  isHost: isHost
                },
                room: action.payload
              };
    case 'UPDATE_ROOM':
      return  { 
                ...state,
                room: action.payload
              };
    case 'UPDATE_ROOM_STATUS':
      return  { 
                ...state,
                room: {
                        ...state.room,
                        status: action.payload
                      }
              };
    case 'UPDATE_GAME':
      return  { 
                ...state,
                room: {
                        ...state.room,
                        game: action.payload
                      }
              };
    default:
      return state;
  }
}
