import { mediumRedTitle, hidden } from '../../core/GlobalStyles'

export default {
  ...hidden,
  ...mediumRedTitle,
  container: {
    height: '95%',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
  },
  flexColumn:{
    flexDirection: 'column'
  },
  canvasClass:{
    height: '96vh',
    width: '96vw'
  },
  cmdButton:{
    flex: '40',
    margin: '5vmin',
    width: '90%'
  },
  myChar:{
    height: '25vmin',
    width: '25vmin'
  },
  arrow:{
    height: '20vmin',
    width: '20vmin'
  },
  playerIsDead: {
    width: '60vmin'
  }
}