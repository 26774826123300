import { mediumRedTitle } from '../../core/GlobalStyles'

export default {
  ...mediumRedTitle,
  container: {
    height: '95%',
    width: '90vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  },
  tileContainer:{
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  tile:{
    width: '20vmin',
  },
  textAlignCenter:{
    textAlign: 'center'
  }
}