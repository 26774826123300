import { primary, greenSuccess, blood } from '../../core/GlobalStyles'

export default {
  question: {
    fontSize: '8vmin',
    color: primary,
    fontFamily: 'revalia'
  },
  winner: {
    color: greenSuccess
  },
  looser: {
    color: blood
  },
  batteryContainer:{
    display: 'flex',
    flex: 'auto'
  },
  playerBat:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 'auto'
  },
  batteryIcon:{
    width: '12vmin',
    height: '12vmin'
  },
  remplissage:{
    position: 'relative',
    width: '5vmin',
    borderRadius: '6px',
    '-webkit-transition': '2s',
    '-moz-transition': '2s',
    '-ms-transition': '2s',
    '-o-transition': '2s',
    transition: '2s'
  }
}