import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import { compose } from 'redux'
import ReactTimeout from 'react-timeout'

import plus from '../../img/icons/plus.svg';
import minus from '../../img/icons/minus.svg';
import check from '../../img/icons/check.svg';
import loading from '../../img/loading.gif';

import PlusOuMoinsStyles from './PlusOuMoinsStyles';
import TimerView from '../timer/TimerView';

function mapStateToProps(state) {
  return {
    game: state.room.game,
    isHost: state.me.isHost,
    socket: state.me.socket
  };
}

class PlusOuMoinsView extends Component {
  constructor(props){
    super(props);
    const { classes, socket = {} } = this.props

    this.state = {
      isTitle: true,
      isCorrect: false,
      itsMore: false,
      value: 0,
      currentLogo: plus,
      animationIconClass: classes.hidden,
      isLoading: false
    }

    // Events reçus depuis socket.io
    socket.on('itsMoreOrLess', this.handleMoreOrLess);
    socket.on('youWon', this.handleWon);
  }

  componentDidMount = () => {
    //Preload images :
    const img = new Image();
    img.src = plus;
    const img2 = new Image();
    img2.src = minus;
    const img3 = new Image();
    img3.src = check;

    this.props.setTimeout(() => {
      this.setState({isTitle: false});
    }, 4000);
  }

  componentWillUnmount(){
    const { socket = {} } = this.props
    socket.removeAllListeners("itsMoreOrLess");
    socket.removeAllListeners("youWon");
  }
  
  handleChange = (e) => {
    this.setState({value: e.target.value});
  }

  handleMoreOrLess = (isMore) => {
    let nextLogo = minus;
    if(isMore){
      nextLogo = plus;
    }
    this.setState({
      animationIconClass:"animated tada",
      isLoading: true,
      currentLogo: nextLogo,
      value:0
    });
    this.props.setTimeout(() => {
      this.setState({animationIconClass:"", isLoading:false});
    }, 1000);
  }
  
  handleWon = () => {
    this.setState({currentLogo: check, animationIconClass:"animated heartBeat slow"});
    this.props.setTimeout(() => {
      this.setState({isCorrect:true});
    }, 2500);
  }
  
  handleEnd = () => {
    this.props.socket.emit('endOfQuestion');
  }
  
  renderHost = () => {
    const { game, classes } = this.props;
    const { isTitle } = this.state;

    if(isTitle){
      return <div className={classes.mediumRedTitle}>
                - Plus ou Moins -<br/>
               Trouvez la bonne réponse dans le temps imparti !
             </div>
    }

    return <div>
              {
                <TimerView time={game.time*1000} endOfTimer={this.handleEnd} />
              }
            <div
              className={classes.question}
              dangerouslySetInnerHTML={{__html: game.question}}>
            </div>
          </div>
  }
  
  isItOk = (e) => {
    e.preventDefault();
    if(!this.state.isLoading){
      this.props.socket.emit('plusOuMoins.isItOk', this.state.value);
    }
    if(this.textField){
      this.textField.focus();
    }
  }

  renderPlayer = () => {
    const { classes } = this.props;
    const { isCorrect, value, isTitle, animationIconClass, currentLogo, isLoading } = this.state;
    if(isCorrect || isTitle){
      return <div>
              <img src={loading} alt='Loading gif'/>
            </div>
    } else {
      return <div className={classes.container}>
                <div className={classes.divLogo}>
                  <img
                    src={currentLogo}
                    alt="Logo + ou -"
                    className={classnames(classes.logo, animationIconClass)}
                  />
                </div>
                <div className={classes.divInput}>
                  <form className={classes.form}>
                    <TextField
                      className={classes.textField}
                      inputRef={field => this.textField = field}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssLabelFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.cssInput,
                          focused: classes.cssLabelFocused,
                          notchedOutline: classes.outlineOfTheInput,
                        },
                      }}
                      value={value ? value : ''}
                      label="Combien ?"
                      variant="outlined"
                      type="number"
                      onChange={this.handleChange}
                      pattern="\d*"
                    />
                    <Button 
                      variant="contained"
                      className={classes.validate}
                      type="submit"
                      onClick={this.isItOk}>
                      {
                        isLoading
                        ? <i className="fas fa-circle-notch fa-spin"></i>
                        : "Valider"
                      }
                    </Button>
                  </form>
                </div>
            </div>
    }
  }

  render() {
    const { isHost } = this.props;
    return (
      <div>
        {
          isHost ? this.renderHost() : this.renderPlayer()
        }
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(PlusOuMoinsStyles),
  ReactTimeout
)(PlusOuMoinsView);