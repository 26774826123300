import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import { compose } from 'redux'
import ReactTimeout from 'react-timeout'

import PrinterInit from '../../sounds/PrinterInit.mp3'
import PrinterPrints from '../../sounds/PrinterPrints.mp3'
import loading from '../../img/loading.gif';

import TimerView from '../timer/TimerView';
import Utils from '../../utilsES6';

import GutenbergStyles from './GutenbergStyles';

function mapStateToProps(state) {
  return {
    game: state.room.game,
    isHost: state.me.isHost,
    socket: state.me.socket,
    playerList: state.room.playerList
  };
}

let t;

class GutenbergView extends Component {
  constructor(props){
    super(props);
    const { socket = {} } = this.props;

    this.state = {
      isTitle: true,
      isOver: false,
      isInputInError: false,
      classToAnimateInput: '',
      words: [],
      currentIndex: 0
    }

    // Events reçus depuis socket.io
    socket.on('gutenberg.receptWords', this.handlereceptWords);
  }

  componentWillUnmount(){
    const { socket = {} } = this.props
    socket.removeAllListeners("gutenberg.receptWords");
  }

  componentDidMount = () => {
    const { socket = {}, isHost } = this.props;
    if(isHost){
      socket.emit('gutenberg.sendMeWords');
      const audio = new Audio(PrinterInit);
      audio.onended = () => {
        const audio2 = new Audio(PrinterPrints);
        audio2.play();
      }
      audio.play();
    }
    this.props.setTimeout(() => {
      this.props.clearTimeout(t);
      t = null;
      this.setState({isTitle: false});
    }, 7000);
  }
  
  handlereceptWords = (words) => {
    this.setState({words: words});
  }
  
  handleChange = (e) => {
    this.setState({word: e.target.value, isInputInError: false});
  }
  
  handleEnd = () => {
    this.props.socket.emit('gutenberg.computeScores');
  }
  
  renderHost = () => {
    const { game, classes, playerList } = this.props;
    const { isTitle } = this.state;
    const { getById } = Utils;
    const results = game.results;

    if(isTitle){
      return <div className={classes.mediumRedTitle}>
                Bienvenue à l'imprimerie AVANT Gutenberg !
                <br/>
                Moines copistes, recopiez à l'identique chaque "mot" qui apparaît sur vos téléphones.
                <br/>
                <span className={classes.textBlue}>Attention aux majuscules/minuscules/accents</span>
            </div>
    }

    return <div>
              {
                <TimerView 
                  time={game.time*1000}
                  endOfTimer={this.handleEnd}
                /> 
              }
            <div className={classes.lettersDone}>
                {
                  playerList.map((player, index) => {
                    const playerResult = results.length ? getById(results, player.id) : {};
                    return  <div className={classes.aPlayerLettersScore} key={index}>
                              <span>{playerResult.lettersDone}</span>
                              <span>{player.name}</span>
                            </div>
                  })
                }
            </div>
          </div>
  }
  

  checkDaWord = (e) => {
    e.preventDefault();
    const {words, word, currentIndex } = this.state;
    const { socket } = this.props;
    let w = word.trim();

    //Fix connerie d'apostrophe Apple !
    w = w.replace('’', '\'').replace('´','\'');

    let newIndex = currentIndex;
    let classToAnimateInput = '';
    let isInputInError  = false;
    if(words[currentIndex] === w){
      newIndex++;
      socket.emit('gutenberg.wordDone', w);
    } else {
      classToAnimateInput = "animated faster shake";
      isInputInError = true;
      this.props.setTimeout(() => {
        this.props.clearTimeout(t);
        t = null;
        this.setState({classToAnimateInput: ''});
      }, 500) ;
    }

    this.setState({ currentIndex: newIndex,
                    word: '',
                    classToAnimateInput: classToAnimateInput,
                    isInputInError: isInputInError
                  });
    if(this.textField){
      this.textField.focus();
    }
  }

  renderPlayer = () => {
    const { classes } = this.props;
    const { isOver, word, words, isTitle, currentIndex, isInputInError, classToAnimateInput } = this.state;

    if(isOver || isTitle){
      return <div>
              <img src={loading} alt='Loading gif'/>
            </div>
    } else {
      return <div className={classes.container}>
                <div className={classes.divSentence}>
                  {words[currentIndex] ? words[currentIndex] : 'Bravo fin de la liste !'}
                </div>
                <div className={classes.divInput}>
                  <form className={classes.form}>
                    <TextField
                      className={classnames(classes.textField, classToAnimateInput)}
                      value={word ? word : ''}
                      variant="outlined"
                      onChange={this.handleChange}
                      inputRef={field => this.textField = field}
                      
                      InputProps={{
                        classes: {
                          root: classes.cssInput,
                          focused: classes.cssLabelFocused,
                          notchedOutline: isInputInError ? classes.outlineError : classes.outline,
                        },
                        // inputProps: {
                        //   autoCapitalize: "none"
                        // }
                      }}
                    />
                    <Button 
                      variant="contained"
                      className={classes.validate}
                      type="submit"
                      onClick={this.checkDaWord}>
                        <i className="far fa-copy"></i>
                    </Button>
                  </form>
                </div>
            </div>
    }
  }

  render() {
    const { isHost } = this.props;
    return (
      <div>
        {
          isHost ? this.renderHost() : this.renderPlayer()
        }
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(GutenbergStyles),
  ReactTimeout
)(GutenbergView);