import React, { Component } from 'react';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views'

import { withStyles } from '@material-ui/core/styles';

import GameStyles from './GameStyles';
import { updatePlayerList, updateGame, setRoomData, updateRoomStatus, updateRoom } from '../core/Actions';
import Swal from '../core/SwalLoader';

import PlayerListView from './playerList/PlayerListView';
import WaitingRoomView from './waitingRoom/WaitingRoomView';
import QuestionView from './question/QuestionView';
import ScoreBoardView from './scoreBoard/ScoreBoardView';

import BottleBreak from '../sounds/BottleBreak.wav'
import Validation from '../sounds/Validation.mp3'
import DisconnectSound from '../sounds/deco.mp3'

import PlusOuMoinsView from './plusOuMoins/PlusOuMoinsView';
import FishBetView from './fishBet/FishBetView';
import BatteryDuelView from './batteryDuel/BatteryDuelView';
import GutenbergView from './gutenberg/GutenbergView';
import MemoryView from './memory/MemoryView';
import EndView from './end/EndView';
import ShiFuMiView from './shiFuMi/ShiFuMiView';
import KnowUrBrosView from './knowUrBros/KnowUrBrosView';
import BulletDodgeView from './bulletDodge/BulletDodgeView';
import BonusView from './bonus/BonusView';
import FragmentationView from './fragmentation/FragmentationView';

const Sounds = {
  BottleBreak: BottleBreak,
  Validation: Validation,
  Disconnect: DisconnectSound
};

function mapStateToProps(state) {
  return {
    id: state.me.id,
    room: state.room,
    game: state.room.game,
    socket: state.me.socket,
    name: state.me.name,
    isHost: state.me.isHost,
    isProduction: state.isProduction
  };
}

class GameView extends Component {
  constructor(props){
    super(props);
    const { socket = {} } = this.props

    // Ici ce sont tous les events reçus depuis socket.io
    socket.on('setRoomForTheGame', this.setRoomForTheGame);
    socket.on('updateRoomStatus', this.handleUpdateRoomStatus);
    socket.on('updateRoom', this.handleUpdateRoom);
    socket.on('updateGame', this.handleUpdateGame);
    socket.on('updatePlayerList', this.handleUpdatePlayerList);
    socket.on('playerDisconnected', this.playerDisconnected);
    socket.on('playSound', this.playSound);
    socket.on('serverHasCrashed', this.serverHasCrashed);
  }

  componentDidMount(){
    if(this.props.isProduction){
      window.addEventListener("beforeunload", this.onLeavePage);
    }
  }

  onLeavePage = (e) => {
    const message = 'Si vous quittez la page vous ne pourrez plus revenir dans la partie';
    e.returnValue = message;     // Gecko, Trident, Chrome 34+
    return message;              // Gecko, WebKit, Chrome <34
  }

  componentWillUnmount(){
    const { socket = {} } = this.props
    socket.removeAllListeners("setRoomForTheGame");
    socket.removeAllListeners("updateRoomStatus");
    socket.removeAllListeners("updateGame");
    socket.removeAllListeners("updatePlayerList");
    socket.removeAllListeners("playerDisconnected");
    socket.removeAllListeners("playSound");
    window.removeEventListener("beforeunload", this.onLeavePage);
  }

  playerDisconnected = (name, newPlayerList, isHostDiconnected) => {
    const { isHost } = this.props;
    console.log('[PLAYER DISCONNECTED] - ', name, ' WAS HOST = ', isHostDiconnected);
    
    if(isHost){
      this.playSound('Disconnect');
    }
    
    if(!isHostDiconnected){
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        type: 'error',
        title: `${name} a été déconnecté`
      });
      this.handleUpdatePlayerList(newPlayerList);
    } else {
      Swal.fire({
        showCancelButton: false,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Putaiiiin fait chier',
        showConfirmButton: true,
        allowOutsideClick: false,
        type: 'error',
        title: `Votre hôte a été déconnecté ...`,
        text: "Partie annulée :("
      }).then((result) => {
        if (result.value) {
          //Reset à la page d'accueil
          this.props.dispatch(setRoomData({}));
        }
      })
    }
  }

  serverHasCrashed = () => {
    Swal.fire({
      showCancelButton: false,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Menu principal',
      showConfirmButton: true,
      allowOutsideClick: false,
      type: 'error',
      title: `Le serveur ne répond plus ...`,
      text: "Il va falloir recommencer !"
    }).then((result) => {
      if (result.value) {
        //Reset à la page d'accueil
        this.props.dispatch(setRoomData({}));
      }
    })
  }

  playSound = (soundId) => {
    const audio = new Audio(Sounds[soundId]);
    audio.play();
  }

  setRoomForTheGame = (room) => {
    this.props.dispatch(setRoomData(room));
    if(this.props.isHost){
      // Get the first game :
      this.props.socket.emit('nextGame');
    }
  }

  handleUpdatePlayerList = (newList) => {
    this.props.dispatch(updatePlayerList(newList));
  }

  handleUpdateGame = (newGame) => {
    this.props.dispatch(updateGame(newGame));
  }

  handleUpdateRoom = (newRoom) => {
    this.props.dispatch(updateRoom(newRoom));
  }

  handleUpdateRoomStatus = (newStatus) => {
    this.props.dispatch(updateRoomStatus(newStatus));
  }

  getContent = () => {
    const { room } = this.props;
    // console.log('[GetContent] - room.status = ', room.status);
    switch(room.status) {
      case 'waiting':
        return <WaitingRoomView />;
      case 'scoreBoard':
        return <ScoreBoardView />;
      case 'end':
        return <EndView />;
      case 'bonus':
        return <BonusView />;
      case 'game':
        return this.getGameView();
      default:
        console.error('[ERREUR] - Pas de status de room de ce type : ', room.status);
        return null;
    }
  }

  getGameView = () => {
    const { game } = this.props;
    // console.log('[GetContent] - game.type = ', game.type);
    switch(game.type) {
      case 'question':
        return <QuestionView />;
      case 'plusOuMoins':
        return <PlusOuMoinsView />;
      case 'fishBet':
        return <FishBetView />;
      case 'batteryDuel':
        return <BatteryDuelView />;
      case 'gutenberg':
        return <GutenbergView />;
      case 'memory':
        return <MemoryView />;
      case 'shifumi':
        return <ShiFuMiView />;
      case 'knowurbros':
        return <KnowUrBrosView />;
      case 'bulletdodge':
        return <BulletDodgeView />;
      case 'fragmentation':
        return <FragmentationView />;
      default:
        console.error('[ERREUR] - Pas de game de ce type : ', game);
        return null;
    }
  }

  render() {
    const { isHost, classes, room, game } = this.props;
    const isPlayerList = isHost 
                        && room.status !== 'scoreBoard'
                        && room.status !== 'end'
                        && room.status !== 'bonus'
                        && game.type !== 'memory'
                        && game.type !== 'bulletdodge'

    return (
      <div className={classes.game}>
        {
          isPlayerList &&
          <div className={classes.playerList}>
            <PlayerListView></PlayerListView>
          </div>
        }
        <div className={classes.main}>
            {
              !isHost ?
                <SwipeableViews resistance disabled>
                  <div className={classes.fullScreen}>
                    { this.getContent() }
                  </div>
                  <div className={classes.fullScreen}>
                    POWERS
                  </div>
                </SwipeableViews>
              :  this.getContent()
            }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(GameStyles)(GameView));
