import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import FlipMove from 'react-flip-move';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { compose } from 'redux';
import ReactTimeout from 'react-timeout';
import SVG from 'react-inlinesvg';

import Swal from '../../core/SwalLoader';
import ticket from '../../img/icons/jackpot.svg';
import ticket_empty from '../../img/icons/jackpot_empty.svg';
import bouteille from '../../img/bouteille.svg';
import bouteille2 from '../../img/bouteille2.svg';
import ScoreBoardStyles from './ScoreBoardStyles';
import Utils from '../../utilsES6';
import ScoreProgressView from './scoreProgress/ScoreProgressView';

import BottleBreak from '../../sounds/BottleBreak.wav';
import FullWin from '../../sounds/FullWin.wav';
import LooseAll from '../../sounds/LooseAll.wav';
import Results from '../../sounds/Results.wav';
import TimesUp from '../../sounds/TimesUp.mp3';

const Sounds = {
  BottleBreak: BottleBreak,
  FullWin: FullWin,
  LooseAll: LooseAll,
  Results: Results,
};

function mapStateToProps(state) {
  return {
    game: state.room.game,
    maxScore: state.room.maxScore,
    playerList: state.room.playerList,
    results: state.room.game.results,
    socket: state.me.socket,
    isHost: state.me.isHost,
    isAdmin: state.me.isAdmin,
    isDrinkingGame: state.room.isDrinkingGame
  };
}

// For debug only :
// let fakeProps = {
//   maxScore: 80,
//   playerList: [
//     {id:1, name:'Emilie', score:49},
//     {id:2, name:'Jean ottaviani', score:40},
//     {id:3, name:'Seb', score:30},
//     {id:4, name:'François', score:20},
//     {id:5, name:'Marny 91', score:10},
//     {id:6, name:'Ludwig', score:51},
//     {id:7, name:'Alicia', score:22}
//   ],
//   results: [
//     {id:1, idReponse:3},
//     {id:2, idReponse:3},
//     {id:3, idReponse:1},
//     {id:4, idReponse:2},
//     {id:5, idReponse:1},
//     {id:6, idReponse:5},
//     {id:7, idReponse:4},
//     {id:8, idReponse:4},
//     {id:9, idReponse:4},
//     {id:10, idReponse:4}
//   ],
//   game: {
//     good:4,
//     score:5,
//     reponses:[
//       { id: 1, texte: "243" },
//       { id: 2, texte: "3^4" },
//       { id: 3, texte: "81" },
//       { id: 4, texte: "123" },
//       { id: 5, texte: "Beaucoup" }
//     ]
//   }
// }

class ScoreBoardView extends Component {
  constructor(props){
    super(props);

    const { playerList, game } = this.props
  
    this.state = {
      hasWon: false,
      canNextGame: false,
      isSplashScreen: true,
      dureeAnimation: 450,
      staggerDurationBy: 15, // tps ajouté à chaque durée d'animation pour les éléments suivants
      staggerDelayBy: 200, // Délais entre chaque éléments animés,
      playerList: playerList,
      type: game.type
    };
  }

  componentDidMount() {
    const { playerList, isHost } = this.props

    //Garde fou au cas où il resterait une popUp qui traine ! 
    Swal.close();

    //Takes a copy of the list ( read-only problems )
    let pL = [...playerList];

    // Substract game.score to all player's scores (already up to date in the server.js)
    pL = this.addOrReduceScores(pL, true);

    // Ordering the PlayerList by score
    pL.sort(this.sortByScore);
    this.setState({playerList: pL});

    if(isHost){
      const audio = new Audio(TimesUp);
      audio.play();
    }

    this.props.setTimeout(this.startScoreBoard, 3000);
  }

  startScoreBoard = () => {
    const { dureeAnimation, staggerDurationBy, staggerDelayBy, playerList } = this.state;
    this.setState({isSplashScreen: false});
    
    const delaisOne = (playerList.length-1) * (staggerDelayBy + staggerDurationBy) + dureeAnimation;
    this.props.setTimeout(this.showCorrection, delaisOne + 1800);
  }

  // Affiche la bonne réponse et les colorent (rouge/vert)
  showCorrection = () => {
    this.setState({hasWon:true, staggerDelayBy:500});
    this.playTheSound();
    this.props.setTimeout(this.showScoreModifs, 1000);
  }

  /** 
   * Joue un son au choix ( uniquement sur Host )
   * - tout le monde a bon
   * - tout le monde a faux
   * - mix des deux
   */
  playTheSound = () => {
    const { playerList, isHost } = this.props;
    if(!isHost){
      return;
    }
    const correct = playerList.filter((p) => this.isGood(p));
    let toPlay = Sounds['Results'];

    // Tous les joueurs ont faux :
    if(correct.length === 0){
      toPlay = Sounds['LooseAll'];
    } else if(correct.length === playerList.length){
      // Jouer un son en mode "youhou tout le monde a bon !"
      toPlay = Sounds['FullWin'];
    }
    const audio = new Audio(toPlay);
    audio.play();
  }
  
  showScoreModifs = () => {
    const { playerList } = this.state
    let pL = [...playerList];

    // Add back points to good players ! 
    pL = this.addOrReduceScores(pL, false);
    this.setState({playerList:pL});
    
    this.props.setTimeout(() => {
      pL.sort(this.sortByScore);
      this.setState({playerList:pL});
      
      this.props.setTimeout(() => {
        // Active le bouton chez l'admin pour passer au jeu suivant
        this.setState({canNextGame:true});
      }, 1000)
    }, 2800);
  }
  
  /**    
   * Reduce the player.score if we are before the result print and add otherwise
   */
  addOrReduceScores = (tab, isReduce) => {
    const { getById } = Utils;
    const { game, results } = this.props;
    let scored = game.score;
    tab.forEach(player => {
      if(game.type === 'gutenberg' || game.type === "knowurbros" || game.type === "fragmentation"){
        const playerResult = getById(results, player.id);
        scored = playerResult.scored;
      }
      if(this.isGood(player)){
        if(isReduce) {
          player.score = (player.score - scored) <= 0 ? 0 : player.score - scored;
        } else {
          player.score = player.score + scored;
          player.scoreAdded = ' +'+scored+' pts'
        }
      }
    });
    return tab;
  }

  /**
   * Tests if the user has won ( @Rework : Traiter tous les mini-jeu avec le 'hasWon' côté back )
   * @returns {Boolean} boolean isGood
   */
  isGood = (player) => {
    const { getById } = Utils;
    const { results, game } = this.props;
    const playerResult = getById(results, player.id);
    switch (game.type) {
      case 'question':
      case 'fishBet':
        return playerResult ? (playerResult.idReponse.toString() === game.good.toString()) : false;
      case 'plusOuMoins':
      case 'batteryDuel':
      case 'gutenberg':
      case 'memory':
      case 'shifumi':
      case 'knowurbros':
      case 'bulletdodge':
      case 'fragmentation':
        return playerResult ? playerResult.hasWon : false;
      default:
        break;
    }
    return 
  }

  /**
   * Sort function by player's score
   */
  sortByScore = (playerA, playerB) => {
    if (playerA.score > playerB.score)
       return -1;
    if (playerA.score < playerB.score)
       return 1;
    return 0;
  }

  handleNextGame = () =>{
    this.props.socket.emit('nextGame');
  }

  renderPlayer = () => {
    const { socket, classes, isAdmin } = this.props;
    const { hasWon, canNextGame } = this.state;

    let classToAdd = ''
    if(hasWon){
      if(!this.isGood(socket)){
        classToAdd = classes.failed;
      } else{
        classToAdd = classes.succes;
      }
    }

    return  <div className={classnames(classToAdd, classes.playerResult)}>
              {
                this.getPlayerAnswerToPrint()
              }
              {
                (canNextGame && isAdmin) && 
                <Button
                  variant="contained"
                  className={classes.btnNext}
                  color="secondary"
                  onClick={this.handleNextGame}>
                  Next
                </Button>
              }
            </div>
  }

  getPlayerAnswerToPrint = () => {
    const { game, socket, classes, results } = this.props;
    const { getById } = Utils;

    let result = getById(results, socket.id);

    switch (game.type) {
      case 'question':
        const answer = (result && result.idReponse ) ? getById(game.reponses, result.idReponse) : false;
        if(answer) {
          return  <>
                    <div>
                      Tu as répondu :
                    </div>
                    <div>
                      {answer.texte}
                    </div>
                  </>
        } else {
           return <div className={classes.failed}> Tu n'as pas répondu :(</div>
        }
      case 'gutenberg':
          result = result ? result : {};
          if(result && result.lettersDone >= 50){
            return <div>Woaw ! Quelle rapidité !</div>
          } else if(result.lettersDone > 40){
            return <div>Bravo !</div>
          } else if(result.lettersDone > 30){
            return <div>Pas trop mal !</div>
          } else if(result.lettersDone > 15){
            return <div>Décevant ! Tu dois mettre du temps pour tes SMS !</div>
          } else {
            return <div>Tu l'as fait exprès ?</div>
          }
      case 'plusOuMoins':
      case 'fishBet':
      case 'batteryDuel':
      case 'memory':
      case 'shifumi':
      case 'knowurbros':
      case 'bulletdodge':
      case 'fragmentation':
          return <div>{this.isGood(socket) ? game.winSentence : game.lostSentence}</div>
      default:
        break;
    }
  }

  getSizesByPlayerLength = (size) => {
    let fs = 3;
    let mw = 15;
    switch(true){
      case size < 5:
        fs = 4;
        mw = 17;
        break;
      case size < 8 :
        fs = 3.5;
        mw = 8;
        break;
      case size < 11:
        fs = 2.3;
        mw = 4;
        break;
      case size < 15:
        fs = 1.7;
        mw = 2;
        break;
      default:
        fs = 1;
        mw = 2;
        break;
    }
    return {
      fontSize: fs+'vmin',
      maxWidth: mw+'vmin'
    };
  }
  
  pickTimesUpText = () => {
    const { rand } = Utils;
    const messages = [
      'TIME\'S UP !',
      'Finiiiiiii',
      '. . .',
      'Résultats :',
      'Voyons voir qui a gagné',
      'Alors sûrs de vous ?',
      'Les scores sont...',
      'Récapitulatif des scores :',
      'Game Over',
      'La suite',
      'Show me what you got',
      'À propos des scores ...',
      'Fin du mini-jeu',
      'Impressionant !',
      'Pas mal pas mal...',
      'Peut mieux faire',
    ]
    return messages[rand(0,messages.length)];
  }

  renderHost = () => {
    //const { game, results, maxScore } = this.fakeProps;
    const { game, results, maxScore } = this.props;
    const { classes } = this.props;
    const { rand, getById } = Utils;
    const { 
      hasWon,
      dureeAnimation,
      staggerDelayBy,
      staggerDurationBy,
      isSplashScreen,
      playerList } = this.state;

    /***************************** SPLASH SCREEN TIME'S UP *****************************************/
    if(isSplashScreen){
      const effets = ['bounce',
                      'flash', 'rubberBand',
                      'shake', 'swing',
                      'tada', 'wobble',
                      'jello', 'heartBeat'];
      const effetApplied = effets[rand(0, effets.length)];
      return (
        <div className={classnames('animated slower '+ effetApplied, classes.bigRedTitle)}>
          {this.pickTimesUpText()}
        </div>
      );
    }
    /***********************************************************************************************/
    
    let classTitleHidden = classes.hidden;
    if(hasWon){
      classTitleHidden = ''
    }

    return (
      <FlipMove 
        className={classes.results}
        appearAnimation="fade"
        enterAnimation="fade"
        easing="linear"
        delay={0}
        duration={dureeAnimation}
        staggerDurationBy={staggerDurationBy}
        staggerDelayBy={staggerDelayBy}
      >
      {
        this.getTitleByGameType(game.type, classTitleHidden)
      }
      { 
        playerList.map((player, index) => {
            const ZIndexStyle = { zIndex: 100 - index };
            let srcImg = bouteille;
            let classReponse = '';
            const playerResult = getById(results, player.id);

            let textAnswer = '';
            switch (game.type) {
              case 'question':
                textAnswer = (playerResult && playerResult.idReponse !== undefined)
                            ? getById(game.reponses, playerResult.idReponse)
                            : false;
                textAnswer = textAnswer ? textAnswer.texte : '';
                break;
              case 'plusOuMoins':
                textAnswer = (playerResult && playerResult.hasWon) ? "Trouvé !" : "Pas trouvé";
                break;
              case 'fishBet':
              case 'batteryDuel':
                textAnswer = this.isGood(player) ? "Gagné" : "Perdu"
                break;
              case 'gutenberg':
                textAnswer = (playerResult ? playerResult.lettersDone : '0') + ' lettres recopiées' 
                break;
              case 'memory':
                textAnswer = this.isGood(player) ? "a gagné" : "a alzeihmer"
                break;
              case 'shifumi':
                textAnswer = this.isGood(player) ? "Roi du SHI-FU-MI" : ""
                break;
              case 'knowurbros':
                const nb = playerResult ? playerResult.nbFriends : 0; 
                textAnswer = this.isGood(player) ? "Tu as au moins "+nb+" ami(s)" : "Non..."
                break;
              case 'bulletdodge':
                textAnswer = this.isGood(player) ? "a survécu" : "† Paix à ton âme †"
                break;
              case 'fragmentation':
                  textAnswer = (playerResult ? playerResult.points : '0') + ' cases fragmentées' 
                break;
              default:
                break;
            }
            
            if(hasWon){
              if(!this.isGood(player)){
                srcImg = bouteille2;
                classReponse = classes.failed;
              } else{
                classReponse = classes.succes;
              }
            }
            const fontAndMaxWidth = this.getSizesByPlayerLength(playerList.length);

            return  <div 
                      key={player.id}
                      className={classes.result}
                      style={ZIndexStyle}>
                      <img 
                        src={srcImg}
                        className={classes.playerImage} 
                        style={{maxWidth: fontAndMaxWidth.maxWidth}}
                        alt='bottle'
                      />
                      <div
                        style={{fontSize: fontAndMaxWidth.fontSize}}
                        className={classnames(classes.infos)}
                      >
                        <span className={classes.flexCenter}>
                          {player.name}
                          <span className={classnames(classReponse, classes.smallMarginLeft)}>
                            {player.scoreAdded}
                          </span>
                          {
                            this.props.isDrinkingGame &&
                            <span className={classnames(classes.flexCenter, classes.marginLeft)}>
                              <SVG 
                                className={classes.iconTicket}
                                src={player.tickets > 0 ? ticket : ticket_empty}>
                              </SVG>
                              <SVG 
                                className={classes.iconTicket}
                                src={player.tickets > 1 ? ticket : ticket_empty}>
                              </SVG>
                            </span> 
                          }
                        </span>
                        <span className={classnames(classes.txtAnswer, classReponse)}>
                           {textAnswer}
                        </span>
                        <ScoreProgressView
                          className={classes.scoreProgress}
                          score={player.score}
                          maxScore={maxScore}
                        />
                      </div>
                      
                    </div>
        })
      }
      </FlipMove>
    );
  }

  getTitleByGameType = (type, hiddenOrNot) => {
    const { game, classes } = this.props;
    const { getById } = Utils;
    switch (type) {
      case 'question':
        const trueAnswer = getById(game.reponses, game.good).texte;
        return (
                  <div className={classnames(classes.trueAnswer, hiddenOrNot)}>
                    Réponse : 
                    <span className={classnames(classes.trueAnswer, classes.blueAnswer)}>
                      {' '+trueAnswer}
                    </span>
                  </div>
                  );
      case 'plusOuMoins':
        return (
            <div className={classnames(classes.trueAnswer, hiddenOrNot)}>
                <div>
                  Qui a réussi ?
                </div>
                <div className={classnames(classes.trueAnswer, classes.blueAnswer)}>
                    {` La bonne réponse était : ${game.good} `}
                </div>
            </div>
          );
      case 'fishBet':
      case 'batteryDuel':
      case 'gutenberg':
      case 'memory':
      case 'shifumi':
      case 'knowurbros':
      case 'bulletdodge':
      case 'fragmentation':
        return (
            <div className={classnames(classes.trueAnswer, hiddenOrNot)}>
                {game.answerTitle}
            </div>
          );
      default:
        break;
    }
  }
  
  render() {
    const { isHost } = this.props;
    if(isHost){
      return this.renderHost();
    } else {
      return this.renderPlayer();
    }
  }
  
}

export default compose(
  connect(mapStateToProps),
  withStyles(ScoreBoardStyles),
  ReactTimeout
)(ScoreBoardView);