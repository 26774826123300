import { primary, medium, xlarge, large, secondary } from '../../core/GlobalStyles'

export default {
  motd: {
    color: primary,
    fontSize: medium,
    fontFamily: 'mogra'
  },
  fontSizeLarge: {
    fontSize: large
  },
  secondary: {
    color: secondary,
    fontSize: medium,
    fontFamily: 'revalia',
  },
  buttonGO:{
    marginTop: '5vmin',
    paddingTop: '1vh',
    fontFamily: 'mogra',
    fontSize: medium
  },
  dontDisplay:{
    display: 'none'
  },
  textTimer:{
    color:primary,
    fontFamily: 'revalia',
    fontSize: xlarge
  },
  paddingABit: {
    padding: '3vmin 0'
  },
  marginTop4vmin: {
    marginTop: '4vmin'
  }
}