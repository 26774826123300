import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import SVG from 'react-inlinesvg';
import { compose } from 'redux'
import ReactTimeout from 'react-timeout'
import SwipeableViews from 'react-swipeable-views'
import Swal from '../../core/SwalLoader';

import Rock from '../../img/shifumi/rock.svg'
import Scissor from '../../img/shifumi/scissor.svg'
import Paper from '../../img/shifumi/paper.svg'
import Crown from '../../img/shifumi/crown.svg'

import RingRound from '../../sounds/RingRound.mp3'
import winnerIsSound from '../../sounds/winnerIs.mp3'

import readySVG from '../../img/shifumi/ready.svg'
import waitingSVG from '../../img/shifumi/waiting.svg'
import fightSVG from '../../img/shifumi/fight.svg'

import loading from '../../img/loading.gif';
import Utils from '../../utilsES6';

import ShiFuMiStyles from './ShiFuMiStyles';

const shiFuMiTiles = [
  {id:0, src:Rock},
  {id:1, src:Paper},
  {id:2, src:Scissor},
]

// For debug only :
// const fakePlayerList = [
//   {id:1, name:'Emilie', score:72},
//   {id:2, name:'Jean ottaviani', score:40},
//   {id:3, name:'Seb', score:30},
//   {id:5, name:'Marny 91', score:10},
//   {id:6, name:'Ludwig', score:51},
//   {id:7, name:'ahahahah', score:51},
// ];

function mapStateToProps(state) {
  return {
    game: state.room.game,
    isHost: state.me.isHost,
    socket: state.me.socket,
    playerList: state.room.playerList
  };
}

let t;

class ShiFuMiView extends Component {
  constructor(props){
    super(props);
    const { socket = {}, isHost, playerList } = this.props
    const { shuffle } = Utils;

    shuffle(playerList);

    this.state = {
      isTitle: true,
      isMyTurn: false,
      rounds: [],
      currentRoundIndex: 0,
      currentMatchIndex: 0,
      pL:JSON.parse(JSON.stringify(playerList)), // Delete the reference to the object
      isShowMatchResult: false,
      isFighting: false,
      isFirstEver: true
    }

    // Events reçus depuis socket.io
    if(isHost){
      socket.on('shifumi.sendAResult', this.handleResult);
    } else {
      socket.on('shifumi.itsYourTurn', this.handleItsMyTurn);
    }
  }

  componentWillUnmount(){
    const { socket = {} } = this.props
    socket.removeAllListeners("shifumi.sendAResult");
    socket.removeAllListeners("shifumi.itsYourTurn");
    this.props.clearTimeout(t);
    t = null;
  }

  handleResult = (choice) => {
    let { currentRoundIndex, currentMatchIndex, rounds } = this.state;
    let { getById } = Utils;
    let match = rounds[currentRoundIndex][currentMatchIndex];
    let whoAnswered = getById([match.p1, match.p2], choice.id);
    whoAnswered.choice = choice.choice;
    
    const hasBothResults = match.p1.choice !== undefined && match.p2.choice !== undefined;

    this.setState({rounds: rounds});

    if(hasBothResults){
      let winner = null;
      const choice = match.p1.choice.toString();
      const choice2 = match.p2.choice.toString();
      //EQUALITY :
      if(choice === choice2){
        //Reset the choice and trigger the match again
        match.p1.choice = undefined;
        match.p2.choice = undefined;

        t = this.props.setTimeout(() => {
          this.props.clearTimeout(t);
          t = null;
          Swal.fire({
            showConfirmButton: false,
            allowOutsideClick: false,
            title: 'Égalité ! Recommencez ...'
          });
          this.setState({rounds: rounds});
          this.triggerCurrentMatch();

        }, 1200) // on voit le résultat ( égalité )
        return;
      }
      //Find the winner :
      switch (choice) {
        case '0':
          winner = (choice2 === '1') ? match.p2 : match.p1;
          break;
        case '1':
          winner = (choice2 === '2') ? match.p2 : match.p1;
          break;
        case '2':
          winner = (choice2 === '0') ? match.p2 : match.p1;
          break;
        default:
          break;
      }
      this.setMatchResult(winner);

    }
  }

  handleItsMyTurn = () => {
    this.setState({isMyTurn: true});
  }

  componentDidMount = () => {
    const { isHost } = this.props;
    
    t = this.props.setTimeout(() => {
      this.props.clearTimeout(t);
      t = null;
      this.setState({isTitle: false});
      if(isHost){
        this.generateFirstRound();
      }
    }, 2700);
  } 
  
  generateFirstRound = () => {
    const { pL } = this.state;

    //MINI-JEU PAS POSSIBLE SEUL : On fait rien si qqn joue seul ( l'appli bug pas avant ?)
    //Garde-fou
    if(pL.length === 1){
      return;
    }

    const isPair = pL.length % 2 === 0;
    let newRounds = [];
    let round1 = [];
    if(isPair){
      for (let i = 0; i < pL.length-1; i=i+2) {
        const p = pL[i];
        const p2 = pL[i+1];
        round1.push({p1: p, p2: p2, winner: false});
      }
    } else {
      // Cas relou d'un nb de player impair : On fait un "précombat" entre les 2 premier 
      // de la liste des joueurs puis on fake des winner pour faire passer tous le monde en "round 2"
      round1.push({p1: pL[0], p2: pL[1], winner: false, isPrecombat: true});
      for (let i = 2; i < pL.length; i++) {
        round1.push({p1: {}, p2: {}, winner: pL[i], isImpaireFake: true});
      }
    }
    newRounds.push(round1);
    this.setState({rounds: newRounds});
    
    t = this.props.setTimeout(() => {
      this.props.clearTimeout(t);
      t = null;
      this.triggerCurrentMatch();
    }, 2000) // Temps où l'on voit le bracket
  }

  /*
            |
          |     |
        |   |   |   
      | | | | | | 
  */
  generateNextRound = () => {
    const { rounds, currentRoundIndex } = this.state;
    const lastRound = rounds[currentRoundIndex] ? rounds[currentRoundIndex] : [];
    let nextRound = [];
    if(lastRound.length === 1){
      // SET THE LAST ROUND : just afficher le winner
      nextRound.push({p1: {}, p2: {}, winner: lastRound[0].winner, isCheat: true});
      return nextRound;
    }
    for (let i = 0; i < lastRound.length; i=i+2) {
      const match = lastRound[i];
      const match2 = lastRound[i+1];
      if(match2 !== undefined){
        match.winner.choice = undefined;
        match2.winner.choice = undefined;

        nextRound.push({p1: match.winner, p2: match2.winner, winner: false});
      } else {
        //Cas impaire ! on créé un match vide et le chanceux passe au round d'après direct :
        nextRound.push({p1: {}, p2: {}, winner: match.winner, isCheat: true});
      }
    }
    return nextRound;
  }

  setMatchResult = (winner) => {
    const { rounds, currentMatchIndex, currentRoundIndex } = this.state;
    let round = rounds[currentRoundIndex];
    round[currentMatchIndex].winner = JSON.parse(JSON.stringify(winner)); // Deletes the reference ( pour pas se marcher sur les pieds)

    // Find the next Round/Match to "play"
    let nextMatchIndex = currentMatchIndex +1;
    let nextRoundIndex = currentRoundIndex;
    let nextRound = -1;
    if(round[nextMatchIndex] === undefined || round[nextMatchIndex].winner !== false){
      /*
                |   
             |     | <--- repêche
           |   |   |   
          | | | | | | 
      */
      // Round + 1 ( soit dernier match soit le match d'après est une "repêche")
      nextMatchIndex = 0;
      nextRoundIndex = currentRoundIndex + 1;
      nextRound = this.generateNextRound();
      rounds.push(nextRound);
    }

    t = this.props.setTimeout(() => {
      this.props.clearTimeout(t);
      t = null;
      this.setState({isShowMatchResult: true});

      t = this.props.setTimeout(() => {
        this.props.clearTimeout(t);
        t = null;
        if(nextRound.length === 1 && nextRound[0].isCheat === true) {
          this.setState({isFighting: false});

          const audio = new Audio(winnerIsSound);
          audio.play();      

          t= this.props.setTimeout(() => {
            this.props.clearTimeout(t);
            t = null;
            this.handleWinner(winner);
          }, 2300); // on voit le winner bouncer 2 fois !
         } else {
          this.setState({
            rounds: rounds,
            currentMatchIndex: nextMatchIndex,
            currentRoundIndex: nextRoundIndex,
            isFighting: false
          });

          t = this.props.setTimeout(() => {
            this.props.clearTimeout(t);
            t = null;
            this.triggerCurrentMatch();
          }, 1000); // on voit le bracket

        }
      }, 1200); // on voit les deux choix + bounce choix gagnant

    }, 800); // on voit les 2 'ticks' vert
  }

  handleWinner = (winner) => {
    const { socket } = this.props;

    Swal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      html: `<img src="${Crown}" 
              style="width:20vmin; height:20vmin" 
              class="animated swing" />
              <div style="font-weight:bold">
                ${winner.name} est le roi du SHI FU MI
              </div>
              `
    });
    // console.log('OVER ! winner =', winner);
    t = this.props.setTimeout(() => {
      this.props.clearTimeout(t);
      t = null;
      Swal.close();
      socket.emit('shifumi.isKing', winner.id);
    }, 2000)
  }

  /**
   * Triggers the current match : rounds[currentRoundIndex][currentMatchIndex]
   */
  triggerCurrentMatch = () => {
    const { rounds, currentMatchIndex, currentRoundIndex, isFirstEver } = this.state;
    let round = rounds[currentRoundIndex];
    let match = round[currentMatchIndex];

    if(isFirstEver){
      Swal.fire({
        showConfirmButton: false,
        allowOutsideClick: false,
        title: 'C\'est l\'heuuuuure du ... DU-DU-DU-DUEL !'
      });
    } else {
      match.class = "heartBeat animated";
      this.setState({rounds:rounds});
    }
    
    const audio = new Audio(RingRound);
    audio.play();
    
    t = this.props.setTimeout(() => {
      this.props.clearTimeout(t);
      t = null;
      Swal.close()
      this.props.socket.emit('shifumi.triggerMatch', match);
      this.setState({isFighting:true, isShowMatchResult: false, isFirstEver: false});
    }, isFirstEver ? 2500 : 1500) // on voit la popUp / le bracket
  }

  renderFight = () =>{
    const { classes } = this.props
    const { rounds, currentMatchIndex, currentRoundIndex, isShowMatchResult } = this.state;
    let round = rounds[currentRoundIndex];

    //Prevents some kind of empty render error ... (?!?)
    if(!round){ return null}

    let match = round[currentMatchIndex];
    let classP1 = '', classP2 = '', animateP1 = '', animateP2 = '';
    if(match.winner){
      classP1 = match.winner.id === match.p1.id ? classes.winner : classes.looser;
      animateP1 = match.winner.id === match.p1.id ? "animated tada" : "";

      classP2 = match.winner.id === match.p2.id ? classes.winner : classes.looser;
      animateP2 = match.winner.id === match.p2.id ? "animated tada" : "";
    }
    
    let svgP1 = waitingSVG, svgP2 = waitingSVG;
    if(match.p1.choice !== undefined) {
      svgP1 = readySVG;
    }
    if(match.p2.choice !== undefined) {
      svgP2 = readySVG;
    }
    if(isShowMatchResult) {
      svgP1 = match.p1.choice !== undefined ? shiFuMiTiles[match.p1.choice].src : svgP1;
      svgP2 = match.p2.choice !== undefined ? shiFuMiTiles[match.p2.choice].src : svgP2;
    }

    return  <div className={classes.popUpContainer}>
              <div className={classnames(classes.popUpContent, classP2, animateP2)}>
                <div className={classes.mogra}>{match.p2.name}</div>
                <div className={classes.svgPopUpDiv}>
                  <SVG src={svgP2}></SVG>
                </div>
              </div>
              <div className={classes.svgFight}>
                <SVG src={fightSVG}></SVG>
              </div>
              <div className={classnames(classes.popUpContent, classP1, animateP1)}>
                <div className={classes.mogra}>{match.p1.name}</div>
                <div className={classes.svgPopUpDiv}>
                  <SVG src={svgP1}></SVG>
                </div>
              </div>
            </div>
  }

  renderRound = (round) => {
    const { classes } = this.props;
    return round.map((match, index) => {
      let classP1 = '', classP2 = '', classImpaireFake ='';
      if(match.winner){
        classP1 = match.winner.id === match.p1.id ? classes.winner : classes.looser;
        classP2 = match.winner.id === match.p2.id ? classes.winner : classes.looser;
      }
      let isLast = index === round.length-1;
      if(match.isImpaireFake){
        classImpaireFake = classes.hidden;
        isLast = true;
      }
      if(match.isPrecombat){
        isLast = true;
      }
      return  <React.Fragment key={index}>
                <div className={classnames(classes.match, match.class, classImpaireFake)}>
                  {
                    match.isCheat
                    ?   <div className={classes.winner}>
                          {match.winner.name}
                        </div>

                    :   <React.Fragment>
                          <div className={classnames(classP1, match.class)}>
                            {match.p1.name}
                          </div>
                          <div className={classnames(classes.vs, match.class)}>VS</div>
                          <div className={classnames(classP2, match.class)}>
                            {match.p2.name}
                          </div>
                        </React.Fragment>
                  }
                </div>
                { !isLast && <hr className={classes.hr} /> }
              </React.Fragment>
    })
  }

  renderHost = () => {
    const { classes } = this.props;
    const { isTitle, rounds, isFighting } = this.state;

    if(isTitle){
      return <div className={classes.mediumRedTitle}>
                Bienvenue au tournoi mondial de Shi - Fu - Mi
             </div>
    }

    return <SwipeableViews resistance index={isFighting ? 1 : 0}> 
              <div className={classes.containerHost}>
                {
                  rounds.map((round, index) => {
                    let kingClass = '';
                    if(round.length === 1 && round[0].isCheat){
                      kingClass = "animated bounce";
                    }
                    return  <div key={index} className={classnames(classes.round, kingClass)}>
                              {
                                this.renderRound(round)
                              }
                            </div>
                  })
                }
              </div>
              <div className={classes.containerFight}>
                { this.renderFight() }
              </div>
            </SwipeableViews>
  }
  
  clickOnTile = (id) => {
    this.props.socket.emit('shifumi.lockChoice', id);
    this.setState({isMyTurn: false});
  }

  renderPlayer = () => {
    const { classes } = this.props;
    const { isTitle, isMyTurn } = this.state;

    if(isTitle || !isMyTurn ){
      return <div>
              <img src={loading} alt='Loading gif'/>
            </div>
    } else {
      return <div className={classes.containerPlayer}>
               {
                  shiFuMiTiles.map((tile, index) => {
                    return  <div
                                key={index}
                                className={classes.tileContainer}>
                              <div
                                onClick={() => this.clickOnTile(tile.id)}
                                className={classes.tile}>
                                <SVG
                                  src={tile.src}
                                >
                                </SVG>
                              </div>
                            </div>
                  })
                }
            </div>
    }
  }

  render() {
    const { isHost } = this.props;
    return isHost ? this.renderHost() : this.renderPlayer();
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(ShiFuMiStyles),
  ReactTimeout
)(ShiFuMiView);