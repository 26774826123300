import { blood, medium, xlarge, greenSuccess } from '../core/GlobalStyles'

export default {
  title: {
    color: blood,
    fontSize: xlarge,
    fontFamily: 'revalia',
  },
  warningColor: {
    backgroundColor: '#fbbf0c !important'
  },
  btnHome: {
    maxWidth: '90%',
    width: '90%',
    height: '14vh',
    margin: '3vh 1vh',
    fontFamily: 'mogra',
    fontSize: medium,
    paddingTop: '1vh'
  },
  btnDownload:{
    height: '14vh',
    margin: '3vh 1vh',
  },
  spanForSvg:{
    height: '10vmin',
    width: '10vmin'
  },
  flex:{
    display:'flex',
    justifyContent: 'center'
  },
  homeLogo:{
    height: '40vh',
    width: '40vh'
  },
  version:{
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    marginRight: '1vmin'
  },
  settings:{
    padding:'2vmin',
    display: 'flex',
    alignItems: 'center',
  },
  maxScore: {
    alignItems:'center',
    flex: '60',
    flexDirection: 'column',
  },
  root:{
    width: '7vmin',
    height: '7vmin',
    color: 'black',
    fontFamily: 'mogra',
  },
  selected:{
    backgroundColor: greenSuccess+' !important',
    color: 'white !important'
  },
  minijeux:{
    display: 'flex',
    flexDirection: 'column',
    flex: '40',
    fontFamily: 'mogra',
    alignContent: 'center'
  }
}