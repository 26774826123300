import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import SVG from 'react-inlinesvg';
import ReactTimeout from 'react-timeout'
import FlipMove from 'react-flip-move';
import { compose } from 'redux'
import Button from '@material-ui/core/Button';

import Utils from '../../utilsES6';
import loading from '../../img/loading.gif';
import selector from '../../img/icons/champagne.svg';
import lever from '../../img/icons/lever.svg';
import BonusStyles from './BonusStyles';
import Swal from 'sweetalert2';

function mapStateToProps(state) {
  return {
    game: state.room.game,
    isHost: state.me.isHost,
    socket: state.me.socket,
    playerList: state.room.playerList,
    isProduction: state.isProduction,
    isAdmin: state.me.isAdmin
  };
}

let t, interval;
let _INDEX_SELECTED = 4;
let _STEP_TIME = 500; // en ms

class BonusView extends Component {
  constructor(props){
    super(props);

    const { socket = {}, isHost } = this.props
    let { shuffle } = Utils;
    const initiBonus = [
      {id:0, index:0, text:"Donne un shot à un voisin"},
      {id:1, index:1, text:"Bois un shot"},
      {id:2, index:2, text:"Tout le monde boit"},
      {id:3, index:3, text:"Donne 4 gorgées"},
      {id:4, index:4, text:"Donne 3 gorgées"},
      {id:5, index:5, text:"Donne 2 gorgées"},
      {id:6, index:6, text:"Corse un verre"},
      {id:7, index:7, text:"Le dernier debout boit"},
      {id:8, index:8, text:"Bois 2 gorgées"},
    ];

    // Mélange l'ordre des bonus
    shuffle(initiBonus);
    initiBonus.map((b, i) => b.index =i);

    // Initial state
    this.state = {
      isTitle: true,
      dureeAnimation: _STEP_TIME-10,
      staggerDurationBy: 0, // tps ajouté à chaque durée d'animation pour les éléments suivants
      staggerDelayBy: 0, // Délais entre chaque éléments animés
      bonus: initiBonus,
      isMyturn: false,
      isLeverPushed: false,
      itsOver: false,
    }

    // Bind socket event
    if(isHost){
      socket.on('bonus.startTheWheel', this.startTheWheel);
    } else{
      socket.on('bonus.itsYourTurn', this.itsMyTurn);
      socket.on('bonus.itsOver', this.bonusWheelDone);
    }
  }

  componentDidMount = () => {
    const { socket = {}, isHost, playerList } = this.props
    t = this.props.setTimeout(() => {
      this.props.clearTimeout(t);
      t = null;
      if(isHost){
        const areAllowed = playerList.filter(p => p.tickets > 1);
        socket.emit('bonus.nextWheeler', areAllowed[0].id);
        areAllowed.splice(0,1);
        console.log('newAreAllowed = ', areAllowed);
        this.setState({isTitle: false, areAllowed: areAllowed});
      }
    }, 1000);
  }

  componentWillUnmount = () => {
    const { socket = {} } = this.props
    socket.removeAllListeners("bonus.startTheWheel");
    socket.removeAllListeners("bonus.itsYourTurn");
    socket.removeAllListeners("bonus.itsOver");
    this.props.clearTimeout(t);
    t = null;
    this.props.clearInterval(interval);
    interval = null;
  }

  bonusWheelDone = () => {
    this.setState({itsOver: true});
  }

  pushLever = () => {
    const { socket = {} } = this.props
    if(!this.state.isLeverPushed){
      this.setState({isLeverPushed: true});
      socket.emit('bonus.startTheWheel');
      t = this.props.setTimeout(() => {
        this.props.clearTimeout(t);
        t = null;
        this.setState({isMyturn: false});
      }, 2500)
    }
  }

  itsMyTurn = () => {
    this.setState({isMyturn: true});
    Swal.fire({
      showConfirmButton: false,
      allowOutsideClick: true,
      title: 'À toi de lancer la roue !',
      timer: 2500
    });
  }

  startTheWheel = () => {
    this.setTurnInterval();
    t = this.props.setTimeout(() => {
      this.props.clearTimeout(t);
      t = null;
      this.stopTheWheel();
    }, 5000);
  }

  stopTheWheel = () => {
    const { bonus } = this.state;
    this.props.clearInterval(interval);
    interval = null;
    t = this.props.setTimeout(() => {
      this.props.clearTimeout(t);
      t = null;
      bonus[_INDEX_SELECTED].selected = true;
      this.setState({bonus: bonus});

      t = this.props.setTimeout(() => {
        this.props.clearTimeout(t);
        t = null;
        this.nextWheeler();
      }, 5000); // temps de lire la règle et la faire

    }, _STEP_TIME+10);
  }

  nextWheeler= () => {
    const { socket = {} } = this.props;
    const { areAllowed } = this.state;
    if(areAllowed[0]){
      socket.emit('bonus.nextWheeler', areAllowed[0].id);
      areAllowed.splice(0,1);
      this.setState({areAllowed: areAllowed});
    } else {
      //Fin des gens avec ticket :
      socket.emit('bonus.updatePostBonus');
    }
  }

  setTurnInterval = () => {
    interval = this.props.setInterval(this.turnOneStep, _STEP_TIME);
  }

  turnOneStep = () => {
    const { bonus } = this.state;
    bonus.map((b) => b.index = (b.index+1)%(bonus.length-1));
    bonus.sort((a,b) => a.index-b.index);
    this.setState({bonus});
  }
  
  handleNextGame = () =>{
    this.props.socket.emit('nextGame');
  }

  renderPlayer = () => {
    const { classes, isAdmin } = this.props;
    const { isMyturn, isLeverPushed, itsOver } = this.state;
    if(itsOver && isAdmin){
      return <Button
          variant="contained"
          className={classes.btnNext}
          color="secondary"
          onClick={this.handleNextGame}>
          Next
        </Button>
    }
    if(!isMyturn){
      return <div>
              <img src={loading} alt='Loading gif'/>
            </div>
    } else {
      return  <div
                className={classnames(classes.lever, isLeverPushed && classes.leverReversed)}
                onClick={this.pushLever}>
                <SVG src={lever}></SVG>
              </div>
                
    }
  }

  renderHost = () => {
    const { classes } = this.props;
    const { isTitle, bonus, dureeAnimation, staggerDurationBy, staggerDelayBy } = this.state;

    if(isTitle){
      return <div className={classes.mediumRedTitle}>
                - La roue des bonus -
                <br/>
                Qui va prendre cher ?
             </div>
    } else {
      return  <div className={classes.container}>
                <div className={classes.selectorContainer}>
                  <div className={classes.selector}>
                    <SVG src={selector}></SVG>
                  </div>
                </div>
                <FlipMove 
                  className={classes.bonusContainer}
                  appearAnimation="fade"
                  enterAnimation="fade"
                  easing="linear"
                  delay={0}
                  duration={dureeAnimation}
                  staggerDurationBy={staggerDurationBy}
                  staggerDelayBy={staggerDelayBy}   
                  >
                  {
                    bonus.map((b, i) => {
                      const ZIndexStyle = { zIndex: i === 0 ? 0 : 20 };
                      let classToAdd = i === 0 ? classes.hidden : classes.bonus;
                      classToAdd = b.selected ? classes.selectedOne : classToAdd;
                      return (
                        <div key={b.id} className={classToAdd} style={ZIndexStyle}>
                          {b.text}
                        </div>
                      )
                    })
                  }
                </FlipMove>
                <div className={classes.selectorContainer}>
                  <div className={classnames(classes.selector, classes.selectorReversed)}>
                    <SVG src={selector}></SVG>
                  </div>
                </div>
              </div>
    }
  }

  render() {
    const { isHost } = this.props;
    return (
      <div>
        {
          isHost ? this.renderHost() : this.renderPlayer()
        }
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(BonusStyles),
  ReactTimeout
)(BonusView);