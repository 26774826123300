import Utils from '../utilsES6'

/**
 * Returns the adminPlayer if he exist, null otherwise
 *
 * @param {any} state
 * @returns boolean
 */
export const adminPlayerSelector = (playerList) => {
  return Utils.getByProp(playerList, true, 'isAdmin');
};