import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import SVG from 'react-inlinesvg';
import { compose } from 'redux'
import ReactTimeout from 'react-timeout'

import classnames from 'classnames';
import loading from '../../img/loading.gif';
import Utils from '../../utilsES6';

import BulletDodgeStyles from './BulletDodgeStyles'
import Button from '@material-ui/core/Button';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Swal from '../../core/SwalLoader';

import bang0 from '../../sounds/bang0.mp3';
import bang1 from '../../sounds/bang1.mp3';
import bang2 from '../../sounds/bang2.mp3';
import bang3 from '../../sounds/bang3.mp3';
import bang4 from '../../sounds/bang4.mp3';

import bullet from '../../img/bulletDodge/bullet.svg';
import Crown from '../../img/shifumi/crown.svg'
import tombstone from '../../img/bulletDodge/tombstone.svg';
import sprite1 from '../../img/bulletDodge/1.svg';
import sprite2 from '../../img/bulletDodge/2.svg';
import sprite3 from '../../img/bulletDodge/3.svg';
import sprite4 from '../../img/bulletDodge/4.svg';
import sprite5 from '../../img/bulletDodge/5.svg';
import sprite6 from '../../img/bulletDodge/6.svg';
import sprite7 from '../../img/bulletDodge/7.svg';
import sprite8 from '../../img/bulletDodge/8.svg';
import sprite9 from '../../img/bulletDodge/9.svg';
import sprite10 from '../../img/bulletDodge/10.svg';
import sprite11 from '../../img/bulletDodge/11.svg';
import sprite12 from '../../img/bulletDodge/12.svg';
import sprite13 from '../../img/bulletDodge/13.svg';
import sprite14 from '../../img/bulletDodge/14.svg';
import sprite15 from '../../img/bulletDodge/15.svg';
import sprite16 from '../../img/bulletDodge/16.svg';
import sprite17 from '../../img/bulletDodge/17.svg';
import sprite18 from '../../img/bulletDodge/18.svg';
import sprite19 from '../../img/bulletDodge/19.svg';
import sprite20 from '../../img/bulletDodge/20.svg';
import sprite21 from '../../img/bulletDodge/21.svg';
import sprite22 from '../../img/bulletDodge/22.svg';
import sprite23 from '../../img/bulletDodge/23.svg';

const Sprites = [sprite1, sprite2, sprite3, sprite4, sprite5, sprite6, sprite7,
  sprite8, sprite9, sprite10, sprite11, sprite12, sprite13, sprite14, sprite15, sprite16,
  sprite17, sprite18, sprite19, sprite20, sprite21, sprite22, sprite23]; 

function mapStateToProps(state) {
  return {
    game: state.room.game,
    isHost: state.me.isHost,
    socket: state.me.socket,
    playerList: state.room.playerList
  };
}
let _fireSounds = [bang0, bang1, bang2, bang3, bang4];
let t, bulletAnimation, newBulletTimeout, levelInterval, lastTimeOut;
let _bulletId = 0;
const _bulletImg = new Image();
const _tombstoneImg = new Image();
_bulletImg.src = bullet;
_tombstoneImg.src = tombstone;
const _NB_STEP_Y = 7;
const _NB_STEP_X = 70;

class BulletDodgeView extends Component {
  constructor(props){
    super(props);
    const { socket = {}, isHost } = this.props;
    this.canvasRef = React.createRef();
    this.state = {
      isTitle: true,
      imDead: false,
      itsOver: false,
      players: [],
      bullets: [],
      lastBulletsY: [],
      me: -1,
      canvasHeight:1000,
      canvasWidth:1000,
      stepY:100,
      nextBulletTime:2500,
      level:1,
      levelTimer: 5*1000
    }
     // Events reçus depuis socket.io
    if(isHost){
      socket.on('bulletdodge.playerMove', this.playerMove);
    } else {
      socket.on('bulletdodge.getMyChar', this.getMyChar);
      socket.on('bulletdodge.instantDeath', this.instantDeath);
    }
  }

  componentDidMount = () => {
    const { isHost } = this.props;

    if(isHost){
      this.chooseChar();
      this.initializeCanvas();
    }

    t = this.props.setTimeout(() => {
      this.props.clearTimeout(t);
      t = null;
      this.setState({isTitle: false});
      t = this.props.setTimeout(() => {
        this.props.clearTimeout(t);
        t = null;
        
        if(isHost){
          this.bulletGenerator();
          this.bulletAnimation();
          this.levelInterval();
        }

      }, 1000); // Tempo de 1sc avant l'arrivée des balles
    }, 3000); // isTitle
  }

  componentWillUnmount(){
    const { socket = {} } = this.props
    socket.removeAllListeners("bulletdodge.playerMove");
    socket.removeAllListeners("bulletdodge.getMyChar");
    this.stopAllTimers();
  }

  stopAllTimers = () => {
    this.props.clearTimeout(t);
    t = null;
    this.props.clearTimeout(newBulletTimeout);
    newBulletTimeout = null;
    this.props.clearInterval(levelInterval);
    levelInterval = null;
    this.props.clearInterval(bulletAnimation);
    bulletAnimation = null;
  }

  componentDidUpdate = () => {
    const { players, bullets, stepY } = this.state;
    if(this.canvasRef.current && !this.state.itsOver){
      const canvas = this.canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      this.detectCollision();
      
      players.forEach(p => {
        if(!p.isDead){
          ctx.drawImage(p.sprite, p.x, p.y*stepY, stepY, stepY);
        } else if(p.isDying){
          ctx.drawImage(_tombstoneImg, p.x, p.y*stepY, stepY, stepY);
        }
      });
      
      bullets.forEach(b => {
        ctx.drawImage(_bulletImg, b.x, b.y*stepY, stepY, stepY);
      });

    }
  }

  instantDeath = () => {
    this.setState({imDead: true});
  }

  detectCollision = () => {
    const { players, bullets, stepY } = this.state;
    let toBeKilled = [];

    /** Detect Collision */
    for (let i = 0; i < bullets.length; i++) {
      const b = bullets[i];
      for (let j = 0; j < players.length; j++) {
        const p = players[j];

        if(b.x < p.x + stepY * 4/5 // un cinquième du svg de la balle DANS le char pour mourrir
          && b.y === p.y
          && !p.isDead){
          toBeKilled.push(p);
        }
      }
    }

    /** Applique la pierre tombale aux morts */
    if(toBeKilled.length > 0){
      t = this.props.setTimeout(() => {
        this.props.clearTimeout(t);
        t = null;
        toBeKilled.forEach(p => {
          p.isDying = false;
        })
        this.setState({players: players});
      }, 2000); // Efface la piere tombale après 2 sc
      
      const survivor = this.isThereASurvivor(players, toBeKilled);
      if(survivor){
        this.effectivlyKill(toBeKilled.filter(p => p.id !== survivor.id));
        this.stopAllTimers();
        this.setState({itsOver: true});
        this.itsOver(survivor, toBeKilled);
      } else {
        this.effectivlyKill(toBeKilled);
        this.setState({players: players}); // Update local des morts
      }
    }
  }

  effectivlyKill = (toBeKilled) => {
    const { socket } = this.props;
    toBeKilled.forEach(p => {
      p.isDead = true;
      p.isDying = true;
      socket.emit('bulletdodge.kill', p.id); // Update server des morts
    })
  }

  itsOver = (survivor, toBeKilled) => {
    const { socket } = this.props;
    let html;
    if(survivor.isLucky){
      const text = toBeKilled.length > 2 ?
      `${toBeKilled.length-1} personnes ont pris une balle `
      : `${toBeKilled[0].name} a pris une balle `;

      html = `<img src="${Crown}" 
      style="width:20vmin; height:20vmin" 
      class="animated swing" />
      <div style="font-weight:bold">
        ${survivor.name} estime toi heureux... ${text}pour que tu restes en vie !
      </div>
      `;
    } else{
      html = `<img src="${Crown}" 
      style="width:20vmin; height:20vmin"
      class="animated swing" />
      <div style="font-weight:bold">
        ${survivor.name} est le roi de l'esquive
      </div>
      `;
    }

    Swal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      html: html
    });

    lastTimeOut = this.props.setTimeout(() => {
      this.props.clearTimeout(lastTimeOut);
      lastTimeOut = null;
      Swal.close();
      socket.emit('bulletdodge.winner', survivor.id);
    }, 4000);
  }

  isThereASurvivor = (players, toBeKilled) => {
    const { rand, getById } = Utils;
    const alive = players.filter(p => {
      return !p.isDead && !getById(toBeKilled, p.id)
    });

    if(alive.length > 1) {
      return false;
    } else if(alive.length === 1) {
      return alive[0];
    } else if(alive.length === 0){
      const lucky = toBeKilled[rand(0, toBeKilled.length)];
      if(lucky){
        lucky.isLucky = true;
        return lucky;
      } else {
        console.error('Duh ! Somme Error there !');
      }
    }
  }

  initializeCanvas = () => {
    const height = window.innerHeight*96/100;
    const width = window.innerWidth*96/100;
    const initialCanv = {
      canvasHeight: Math.round(height),
      canvasWidth: Math.round(width),
      stepY: Math.round(height/_NB_STEP_Y),
      stepX: Math.round(width/_NB_STEP_X),
    }
    this.setState({...initialCanv});
  }

  chooseChar = () => {
    const { playerList, socket } = this.props;
    const { players } = this.state;
    const { rand } = Utils;
    let took = [];
    playerList.forEach((p) => {
      const r = rand(0, Sprites.length, took); //TODOLATER gérer le cas de plus de joueurs que de sprites... >23 ...
      took.push(r);
      //Preload Img so that it can be used in ctx.drawImage
      const img = new Image();
      img.src = Sprites[r];
      players.push({id:p.id, spriteId:r, sprite:img, x:0, y:0, isDead:false, name:p.name});
    });
    this.setState({players: players});
    socket.emit('bulletdodge.setPlayerChar', players);
  }
  
  getMyChar = (charId) => {
    this.setState({me: charId});
  }


  levelInterval = () => {
    const { levelTimer } = this.state;
    levelInterval = this.props.setInterval(() =>{
      this.setState({level: this.state.level+1});
    }, levelTimer);
  }

  bulletAnimation = () => {
    const { stepX, canvasWidth } = this.state;
    const { rand } = Utils;
    bulletAnimation = this.props.setInterval(() => {
      const { bullets } = this.state;
      let newBullets = [];
      bullets.forEach(b => {
        b.x -= stepX;
        if(b.x >= -stepX){
          newBullets.push(b);
        }
        if(b.x > canvasWidth && b.x <= canvasWidth+stepX) {
          const audio = new Audio(_fireSounds[rand(0, _fireSounds.length)]);
          audio.play();
        }
      });
      this.setState({bullets: newBullets});
    }, 35);
  }

  /**
   * Generate n bullets
   */
  addBullet = (nb) => {
    const { bullets, lastBulletsY, canvasWidth, stepX } = this.state;
    const { rand } = Utils;
    for (let i = 0; i < nb; i++) {
      const yBullet = rand(0, _NB_STEP_Y+1, lastBulletsY);

      // Pour désynchroniser les balles on ajoute un x 
      // compris entre 0 (direct) et 10,20 ou 30 stepX
      const xBullet = canvasWidth + rand(0, 4) * (stepX*10);

      //console.log('FIIIIRE : ', {id:_bulletId, x:xBullet , y:yBullet});

      bullets.push({id:_bulletId, x:xBullet , y:yBullet});
      _bulletId++;
      lastBulletsY.push(yBullet);

      // Intervalle de 4 balles avant de revenir au même Y
      if(lastBulletsY.length > 3){
        lastBulletsY.splice(0, lastBulletsY.length-3);
      }
    }
    this.setState({bullets:bullets, lastBulletY:lastBulletsY})
  }

  bulletGenerator = () => {
    const { level, nextBulletTime } = this.state;
    const { rand } = Utils;
    const nb = rand(1 + level, 4+level);
    this.addBullet(nb);
    newBulletTimeout = this.props.setTimeout(() => {
      this.props.clearTimeout(newBulletTimeout);
      newBulletTimeout = null;
      this.bulletGenerator();
    }, nextBulletTime);
  }

  renderHost = () => {
    const { classes } = this.props;
    const { isTitle, canvasHeight, canvasWidth } = this.state;

    if(isTitle){
      return <div className={classes.mediumRedTitle}>
               - Bullet Dodge - <br/>
                Restez en vie !
             </div>
    } else {
      return  <div className={classes.container}>
                <canvas
                  className={classes.canvasClass}
                  ref={this.canvasRef}
                  height={canvasHeight}
                  width={canvasWidth}
                />
              </div>
    }
  }

  move = (direction) => {
    const { socket } = this.props;
    socket.emit('bulletdodge.move', direction);
  }

  playerMove = (data) => {
    const { players } = this.state;
    const { getById } = Utils;
    let p = getById(players, data.id);
    if(!p.isDead){
      switch(data.direction){
        case 'up':
          if(p.y>0){
            p.y --;
          }
          break;
        case 'down':
          if(p.y<_NB_STEP_Y-1){
            p.y ++;
          }
          break;
        default:
          break;
      }
      this.setState({players: players});
    }
  }
  

  renderPlayer() {
    const { classes } = this.props;
    const { isTitle, imDead, me } = this.state;
    if(isTitle){
      return <div>
              <img src={loading} alt='Loading gif'/>
            </div>
    } else if(imDead){
      return <div>
              <img src={tombstone} className={classes.playerIsDead} alt='Tombstone'/>
            </div>
    }
    else {
      return <div className={classnames(classes.container, classes.flexColumn)}>
               <Button
                variant="contained"
                className={classes.cmdButton}
                color="primary"
                onClick={() => this.move('up')}>
                  <ArrowDropUp className={classnames(classes.extendedIcon, classes.arrow)} />
              </Button>
              <div className={classes.myChar}>
                {
                  me !== -1 && <SVG src={Sprites[me]}></SVG>
                }
              </div>
               <Button
                variant="contained"
                className={classes.cmdButton}
                color="primary"
                onClick={() => this.move('down')}>
                  <ArrowDropDown className={classnames(classes.extendedIcon, classes.arrow)} />
              </Button>
            </div>
    }
  }

  render() {
    const { isHost } = this.props;
    return isHost ? this.renderHost() : this.renderPlayer();
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(BulletDodgeStyles),
  ReactTimeout
)(BulletDodgeView);