/**
 * 
 *  Fichier de config de SWAL 2
 * 
 */

import SwalNoReact from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

let swal = withReactContent(SwalNoReact);
swal = swal.mixin({
                    reverseButtons: true,
                    confirmButtonColor: '#5cb85c'
                  });

export default swal;