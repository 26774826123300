import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import SVG from 'react-inlinesvg';
import { compose } from 'redux';
import ReactTimeout from 'react-timeout';

import podiumSVG from '../../img/icons/podium.svg';
import firstSVG from '../../img/icons/first.svg';
import secondSVG from '../../img/icons/second.svg';
import thirdSVG from '../../img/icons/third.svg';

import applause from '../../sounds/applause.mp3';

import Utils from '../../utilsES6';
import EndStyles from './EndStyles';

const podiumSVGArray = [firstSVG, secondSVG, thirdSVG];

function mapStateToProps(state) {
  return {
    game: state.room.game,
    isHost: state.me.isHost,
    socket: state.me.socket,
    playerList: state.room.playerList
  };
}

//For debbuging :
// const fakePlayerList = [
//   {id:1, name:'Emilie', score:72},
//   {id:2, name:'Jean ottaviani', score:40},
//   {id:3, name:'Seb', score:30},
//   {id:5, name:'Marny 91', score:10},
//   {id:6, name:'Ludwig', score:51},
//   {id:7, name:'Alicia', score:22}
// ];

class EndView extends Component {
  constructor(props){
    super(props);
    this.state = {
      isTitle: true,
      showResult: false,
      showBadge: false,
      isListening: false,
      podium: [],
      maxScore: 0
    }
  }

  componentWillUnmount(){
    const { socket = {} } = this.props
    socket.removeAllListeners("end.receptSentences");
  }

  componentDidMount = () => {
    this.get3winners();
    this.props.setTimeout(() => {
      this.setState({isTitle: false});
      this.props.setTimeout(() => {
        this.setState({showResult: true});
      }, 400);
      this.props.setTimeout(() => {
        this.setState({showBadge: true});
      }, 4000);
    }, 4000);
  }

  get3winners = () => {
    const { playerList } = this.props;
    let { podium } = this.state;
    let { shuffle } = Utils;

    const pL = playerList;
    pL.sort(this.sortByScore);
    podium[0] = pL[0] ? pL[0].id : -1;
    podium[1] = pL[1] ? pL[1].id : -1;
    podium[2] = pL[2] ? pL[2].id : -1;

    const maxScore = pL[0] ? pL[0].score : 0;

    shuffle(pL);
    this.setState({podium: podium, playerList: pL, maxScore:maxScore});
  }

  /**
   * Sort function by player's score
   */
  sortByScore = (playerA, playerB) => {
    if (playerA.score > playerB.score)
       return -1;
    if (playerA.score < playerB.score)
       return 1;
    return 0;
  }
  
  renderHost = () => {
    const { classes, playerList } = this.props;
    const { isTitle, podium, maxScore, showResult, showBadge } = this.state;

    if(isTitle){
      return  <div className={classes.mediumRedTitleWithSVG}>
                <div style={{'margin-bottom': '10vmin'}}>
                  Qui s'est le mieux battu ? 
                </div>
                <div className={classes.hostPodium}>
                  <SVG src={podiumSVG}>
                  </SVG>
                </div>
              </div>
              
    }

    return <div className={classes.container}>
              {
                playerList.map((player, index) => {
                  const indexPodium = podium.indexOf(player.id);
                  const maxHeight = 60;
                  const maxHSLA = 100;

                  let height = Math.round(player.score / maxScore * maxHeight);
                  let score = player.score;
                  let hsla = Math.round(player.score / maxScore * maxHSLA) - 20;

                  if(!showResult){
                    height = 0;
                    score = 0;
                    hsla = 0;
                  }

                  return <div className={classes.score} key={index}>
                            { 
                              (indexPodium !== -1 && showBadge) && 
                                <div className={classes.badgeSVG}>
                                  <SVG src={podiumSVGArray[indexPodium]}>
                                  </SVG>
                                </div>
                            }
                            <div 
                              style={
                              {backgroundColor:'hsl('+hsla+', 70%, 50%)',
                              height: height+'vh'}}
                              className={classes.scoreBar}>
                            </div>
                            <span
                            className={classes.scorePoints}
                            style={{color: 'hsl('+hsla+', 70%, 50%)'}}>{score}</span>
                            <span>{player.name}</span>
                         </div>
                })
              }
          </div>
  }

  renderPlayer = () => {
    const { classes, socket, playerList } = this.props;
    const { showResult, podium, isTitle } = this.state;
    const { getById } = Utils;
    
    const player = getById(playerList, socket.id);
    const indexPodium = podium.indexOf(player.id);

    if(!showResult || isTitle){
      return <div className={classes.hostPodium}>
               <SVG
                  src={podiumSVG}
                  className={classes.grandSVG}
                >
                </SVG>
            </div>
    } else {
      if(indexPodium !== -1){
        const audio = new Audio(applause);
        audio.play();
        return <div className={classes.hostPodium}>
                <SVG src={podiumSVGArray[indexPodium]}>
                </SVG>
              </div>
      } else {
        return <div>L'important ... C'est d'avoir participé non ?</div>
      }
    }
  }

  render() {
    const { isHost } = this.props;
    return isHost ? this.renderHost() : this.renderPlayer();
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(EndStyles),
  ReactTimeout
)(EndView);