import { mediumRedTitle } from '../../core/GlobalStyles'

export default {
  ...mediumRedTitle,
  container: {
    height: '100%',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
  },
  flexRow: {
    display: 'flex'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  canvasClass:{
    height: '96vh',
    width: '96vw'
  },
}