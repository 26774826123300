import { medium, blood, blue, bigRedTitle, hidden, dark } from '../../core/GlobalStyles'

export default {
  ...bigRedTitle,
  results: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '97vh'
  },
  result: {
    display: 'flex',
    height: '100%',
    marginBottom: '1vmin',
    backgroundColor: dark
  },
  score:{
    color: blood
  },
  playerResult :{
    fontSize: '8vmin'
  },
  trueAnswer:{
    width: '100%',
    fontSize: '5vmin',
    marginBottom: '1vmin'
  },
  playerImage: {
    flex: '15',
    maxWidth: '15vmin'
  },
  infos:{
    display: 'flex',
    height: '100%',
    flex: '85',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '0 5vh'
  },
  scoreProgress:{
    width: '100%'
  },
  failed:{
    color: blood
  },
  succes:{
    color: "#008000"
  },
  btnNext:{
    maxWidth: '90%',
    width: '90%',
    height: '14vh',
    margin: '3vh 1vh',
    fontFamily: 'mogra',
    fontSize: medium,
    paddingTop: '1vh'
  },
  blueAnswer:{
    color: blue
  },
  ...hidden,
  flexCenter:{
    display: 'flex',
    alignItems: 'baseline',
  },
  marginLeft:{
    marginLeft: '3vmin'
  },
  smallMarginLeft:{
    marginLeft: '1vmin'
  },
  iconTicket:{
    width: '5vmin',
    height: '5vmin'
  },
  
}