import {primary, secondary} from './GlobalStyles'

const palette = {
  primary: {
    main: primary
  },
  secondary: {
    main: secondary
  }
}

export default palette