import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from './core/History';
import Reducer from './core/Reducer';
import thunk from 'redux-thunk';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Palette from './core/Palette'

const theme = createMuiTheme({
                              palette: Palette,
                              typography: {
                                useNextVariants: true,
                                suppressDeprecationWarnings: true
                              }
                            });
const store = createStore(Reducer, applyMiddleware(thunk));

const startApp = () => {
  ReactDOM.render(
    <Provider store={store}>
                    <MuiThemeProvider theme={theme}>
                      <Router history={history}>
                        <App />
                      </Router>
                    </MuiThemeProvider>
                  </Provider>,
                  document.getElementById('root')
                  );
  if(window && window.plugins && window.plugins.insomnia){
    window.plugins.insomnia.keepAwake();
  }
}

if(!window.cordova) {
  startApp();
} else {
  document.addEventListener('deviceready', startApp, false)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
