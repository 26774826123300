import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Progress } from 'react-sweet-progress';
import { compose } from 'redux'
import ReactTimeout from 'react-timeout'

import { dark } from '../../../core/GlobalStyles'
import ScoreProgressStyles from './ScoreProgressStyles';

class ScoreProgressView extends Component {
  constructor(props){
    super(props);
    const { score=0, maxScore=100 } = this.props;

    // Temps d'apparition de la progress bar :
    const time = 1500;
    let maxCurrentPercent = score / maxScore * 100;
    if(score >= maxScore){
      maxCurrentPercent = 100;
    }

    let interval = this.props.setInterval(this.updateInterval, 200);

    this.state = {
      maxCurrentPercent: maxCurrentPercent,
      maxCurrentScore: score,
      timeRemaining: time,
      totalTime: time,
      startPercent: 0,
      startScore: 0,

      percent: 0,
      score: '0',
      color: dark,
      interval
    }
  }

  componentWillUnmount = () => {
    this.props.clearInterval(this.state.interval);
  }

  componentDidUpdate = (prevProps) => {
    const { score=0, maxScore=100 } = this.props;
    if(score !== prevProps.score){
      const time = 3000;
      let maxCurrentPercent = score / maxScore * 100;
      if(score >= maxScore){
        maxCurrentPercent = 100;
      }

      this.setState({
        maxCurrentPercent: maxCurrentPercent,
        maxCurrentScore: score,
        timeRemaining: time,
        totalTime: time,
        startPercent: this.state.percent,
        startScore: this.state.score,

        percent: this.state.percent,
        score: this.state.score.toString()
      }, () => {
        //Redémarre l'interval en settant l'id de l'interval
        this.setState({
          interval:this.props.setInterval(this.updateInterval, 200)
        })
      })
    }
  }

  updateInterval = () => {
    const { maxCurrentScore, totalTime, timeRemaining, maxCurrentPercent, startPercent, startScore } = this.state;

    // Update every 'refresh'ms ( --> default = 200ms )
    const { refresh=200 } = this.props;
    const nextTimeRemaining = timeRemaining - refresh;

    const percentTime = 100 - (nextTimeRemaining / totalTime * 100);

    let nextPercent = (maxCurrentPercent * percentTime / 100) + startPercent;
    if(nextPercent >= maxCurrentPercent){
      nextPercent = maxCurrentPercent;
    }
    
    let nextScore = Math.round((maxCurrentScore * percentTime / 100) + parseInt(startScore, 10));
    if(nextScore > this.props.maxScore || nextScore > maxCurrentScore){
      nextScore = maxCurrentScore;
    }
    
    // 120 en H de HSLA = vert ( on veut pas du % mais du pour120 ....)
    const nextHSLA = nextPercent*120/100; 

    this.setState({
      timeRemaining:nextTimeRemaining,
      percent: Math.round(nextPercent),
      color: `hsla(${nextHSLA}, 87%, 46%)`,
      score: nextScore.toString()
    });

    if(nextTimeRemaining<=0){
      this.props.clearInterval(this.state.interval);
      this.setState({interval: null});
    }
  }

  render() {
    const { className, classes } = this.props;
    const { percent, color, score } = this.state;

    const theme = {
      default: {
        color: dark, // Black --> invisible
        symbol: '0'
      },
      currentTheme: {
        color: color,
        symbol: score
      }
    }

    return (
      <div className={className}>
        <Progress
          symbolClassName={classes.textTimer}
          percent={percent}
          status="currentTheme"
          theme={theme}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(ScoreProgressStyles),
  ReactTimeout
)(ScoreProgressView);