import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import ReactTimeout from 'react-timeout'
import { compose } from 'redux'

import wine from '../../img/icons/wineGlass.svg';
import wineSelected from '../../img/icons/wineGlassFull.svg';
import loading from '../../img/loading.gif';
import KnowUrBrosStyles from './KnowUrBrosStyles';

function mapStateToProps(state) {
  return {
    game: state.room.game,
    isHost: state.me.isHost,
    socket: state.me.socket,
    playerList: state.room.playerList,
    isProduction: state.isProduction
  };
}

let t;

class KnowUrBrosView extends Component {
  constructor(props){
    super(props);

    const { socket = {} } = this.props
    
    this.state = {
      isTitle: true,
      isWait: false,
      isGuessAnswers: false,
      isMyAnswer: false,
      isCorrection: false,
      myAnswer: undefined,
      guess: {},
      correction: [],
    }

    socket.on('knowurbros.timeToGuess', this.startGuessing);
    socket.on('knowurbros.correction', this.startCorrection);
  }

  componentWillUnmount = () => {
    const { socket = {} } = this.props
    socket.removeAllListeners("knowurbros.timeToGuess");
    socket.removeAllListeners("knowurbros.correction");
    this.props.clearTimeout(t);
    t = null;
  }

  componentDidMount = () => {
    t = this.props.setTimeout(() => {
      this.props.clearTimeout(t);
      t = null;
      this.setState({isTitle: false, isMyAnswer: true});
    }, 4000);
  }

  startGuessing = () => {
    this.setState({isWait:false, isGuessAnswers: true, isMyAnswer: false});
  }

  startCorrection = (correct) => {
    const { isHost, socket } = this.props;
    this.setState({isWait:false, isCorrection: true, correction: correct});
    if(isHost){
      t = this.props.setTimeout(() => {
        this.props.clearTimeout(t);
        t = null;
        socket.emit('endOfQuestion');
      }, 7000);
    }
  }

  lockMyAnswer = () => {
    this.setState({isWait: true});
    this.props.socket.emit('knowurbros.lockMyAnswer', this.state.myAnswer);
  }
  
  lockMyGuess = () => {
    this.setState({isWait: true});
    console.log('State.guess = ', this.state.guess);
    this.props.socket.emit('knowurbros.checkDaGuess', this.state.guess);
  }

  handleMyChange = (e) => {
    this.setState({myAnswer: e.target.value});
  }

  pickForPlayer = (pId, choice) => {
    const { guess, isCorrection } = this.state;
    if(isCorrection){
      return;
    }
    guess[pId] = choice;
    this.setState({guess: guess});
  }

  renderPlayer = () => {
    const { classes, game, playerList, socket } = this.props;
    const { isWait,
            isTitle,
            isCorrection,
            isMyAnswer,
            guess,
            correction,
            isGuessAnswers,
            myAnswer } = this.state;

    if(isWait || isTitle){
      return <div>
              <img src={loading} alt='Loading gif'/>
            </div>
    } else if(isMyAnswer) {
      return  <div className={classes.container}>
                <div className={classes.explication}>
                  Pour toi, c'est :
                </div>
                <div className={classes.answerContainer}>
                  <FormControlLabel
                    label={<span className={classes.repTexte}>{game.choice1}</span>}
                    labelPlacement="start"
                    control={
                      <Checkbox
                        icon={<img 
                                className={classes.checkBoxIcon} 
                                src={wine} 
                                alt="select"
                              />}
                        checkedIcon={<img 
                                className={classes.checkBoxIcon} 
                                src={wineSelected} 
                                alt="selected"
                              />}
                        value="1"
                        checked={myAnswer === "1"}
                        onChange={this.handleMyChange}
                      />
                    }
                  />
                  <FormControlLabel
                    label={<span className={classes.repTexte}>{game.choice2}</span>}
                    control={
                      <Checkbox
                        icon={<img 
                                className={classes.checkBoxIcon} 
                                src={wine} 
                                alt="select"
                              />}
                        checkedIcon={<img 
                                className={classes.checkBoxIcon} 
                                src={wineSelected} 
                                alt="selected"
                              />}
                        value="2"
                        checked={myAnswer === "2"}
                        onChange={this.handleMyChange}
                      />
                    }
                  />
                </div>
                { this.renderValidateButton() }
              </div>
    } else if(isGuessAnswers) {
      return <div className={classes.container}>
                <div className={classes.explication}>
                  Et pour eux, à ton avis :
                </div>
                <div className={classnames(classes.answerContainer, classes.flexColumn)}>
                  {
                    playerList.map((p, index) => {

                      // Skip the player itself
                      if(p.id === socket.id){
                        return null;
                      }

                      let correctionClass = ''
                      if(isCorrection){
                        console.log('isCorrection : correction = ', correction, " guess[p.id] = ", guess[p.id],  "   === : ", correction[p.id] === guess[p.id] )
                        correctionClass = correction[p.id] === guess[p.id] ? classes.ok : classes.ko;
                      }

                      const choiceForPlayer = guess[p.id];
                      return  <div key={index} className={classes.rep}>
                                <FormControlLabel
                                  label={<span className={classes.repTexte}>{game.choice1}</span>}
                                  labelPlacement="start"
                                  control={
                                    <Checkbox
                                      icon={<img 
                                              className={classes.checkBoxIcon} 
                                              src={wine} 
                                              alt="select"
                                            />}
                                      checkedIcon={<img 
                                              className={classes.checkBoxIcon} 
                                              src={wineSelected} 
                                              alt="selected"
                                            />}
                                      value="1"
                                      checked={choiceForPlayer === "1"}
                                      onChange={() => this.pickForPlayer(p.id, "1")}
                                    />
                                  }
                                />
                                <div className={classnames(classes.playerName, correctionClass)}>
                                  {p.name}
                                </div>
                                <FormControlLabel
                                  label={<span className={classes.repTexte}>{game.choice2}</span>}
                                  control={
                                    <Checkbox
                                      icon={<img 
                                              className={classes.checkBoxIcon} 
                                              src={wine} 
                                              alt="select"
                                            />}
                                      checkedIcon={<img 
                                              className={classes.checkBoxIcon} 
                                              src={wineSelected} 
                                              alt="selected"
                                            />}
                                      value="2"
                                      checked={choiceForPlayer === "2"}
                                      onChange={() => this.pickForPlayer(p.id, "2")}
                                    />
                                  }
                                />

                              </div>
                    })
                  }
                </div>
                { this.renderValidateButton() }
            </div>
    }
  }
  
  renderValidateButton = () => {
    const { classes } = this.props;
    const { isMyAnswer,
            isGuessAnswers,
            isCorrection,
            myAnswer } = this.state;
    let isHidden, onClick;
    if(isMyAnswer) {
      isHidden = myAnswer === undefined;
      onClick = this.lockMyAnswer;
    } else if(isGuessAnswers){
      onClick = this.lockMyGuess;
      isHidden = !this.hasAnsweredForAll();
    }

    if(isCorrection){
      isHidden = true;
    }

    return  <Button
              variant="contained"
              className={classnames(classes.validate, isHidden && classes.hidden)}
              onClick={onClick}>
              Valider
            </Button>
  }

  hasAnsweredForAll = () => {
    const { playerList, socket } = this.props;
    const { guess } = this.state;
    for (let i = 0; i < playerList.length; i++) {
      if(socket.id === playerList[i].id) continue;
      if(!guess[playerList[i].id]){
        return false;
      }
    }
    return true
  }

  renderHost = () => {
    const { game, classes } = this.props;
    const { isTitle, isMyAnswer, isGuessAnswers, isCorrection } = this.state;

    if(isCorrection){
      return <div className={classes.mediumRedTitle}>
                Correction. 
             </div>
    }
    if(isTitle){
      return <div className={classes.mediumRedTitle}>
                Know your bros !
                <br/>
                <br/>
                Did you know this 'bout your 'sis ?
             </div>
    } else if(isMyAnswer) {
      return <div className={classes.mediumRedTitle}>
                Répondez à la question suivante :
              <div className={classes.question}>{game.question}</div>
            </div>
    } else if(isGuessAnswers){
      return <div className={classes.mediumRedTitle}>
                Maintenant, devinez ce qu'ont répondu les autres !
              <div className={classes.question}>{game.question}</div>
            </div>
    }
  }

  render() {
    const { isHost } = this.props;
    return (
      <div>
        {
          isHost ? this.renderHost() : this.renderPlayer()
        }
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(KnowUrBrosStyles),
  ReactTimeout
)(KnowUrBrosView);