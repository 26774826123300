import { dark } from './core/GlobalStyles'

export default {
  app: {
    backgroundColor: dark,
    minHeight: '100vh',
    maxHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: 'white',
    textAlign: 'center',
    fontFamily: 'mogra'
  }
}