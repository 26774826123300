import { primary, secondary, blue, successButton, medium, hidden, mediumRedTitle, greenSuccess } from '../../core/GlobalStyles'

export default {
  ...mediumRedTitle,
  container: {
    display: 'flex',
    height: '95vh',
    width: '90vw',
    flexDirection: 'column',
    alignItems: 'center'
  },
  divSentence:{
    display: 'flex',
    margin: '10vmin 0'
  },
  divInput:{
    display: 'flex'
  },
  validate:{
    ...successButton,
    fontSize: medium,
    display: 'flex',
    flex: '10',
    marginLeft: '5vmin'
  },
  ...hidden,
  form:{
    display: 'flex'
  },
  textDone:{
    color: greenSuccess,
    marginRight: '1vmin'
  },
  lettersDone:{
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  aPlayerLettersScore:{
    display: 'flex',
    flexDirection: 'column'
  },
  textBlue:{
    color: blue
  },
  

  // INPUT :
  textField:{
    flex: '20'
  },
  cssLabelFocused:{
    color: secondary+' !important',
  },
  cssInput:{
    color: secondary,
    fontSize: medium,
    fontFamily: 'mogra'
  },
  outline:{
    borderColor: secondary+' !important',
    borderWidth: '3px !important'
  },
  outlineError:{
    borderColor: primary+' !important',
    borderWidth: '3px !important'
  },
}