import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import SVG from 'react-inlinesvg';

import io from 'socket.io-client';

import logo from '../img/logo_sansfond.svg';
import Cogs from '../img/icons/cogs.svg';
import smartphone from '../img/smartphone.svg';
import HomeStyles from './HomeStyles';
import { setUserFields, setRoomData } from '../core/Actions';
import Swal from '../core/SwalLoader';
import NoSleep from '../core/noSleep.min.js';


const version = "1.1.1";

function mapStateToProps(state) {
  return {
    name: state.me.name,
    socket: state.me.socket,
    room: state.room,
    id: state.me.id,
    isProduction: state.isProduction
  };
}

class HomeView extends Component {
  constructor(props){
    super(props);
    const { isProduction } = this.props
    this.state = {
      isApp: window.hasOwnProperty("cordova"),
      isSettingsOpen: false,
      maxScore: 20,
      isDrinkingGame: false,
      minijeux: [ 
        {id: 0, type: 'question', name:'QCM', checked: true},
        {id: 1, type: 'plusOuMoins', name:'+ ou -', checked: true},
        {id: 2, type: 'fishBet', name:'Choisi ton chat', checked: true},
        {id: 3, type: 'batteryDuel', name:'Duel de batterie', checked: true},
        {id: 4, type: 'gutenberg', name:'Gutenberg', checked: true},
        {id: 5, type: 'memory', name:'Memory', checked: true},
        {id: 6, type: 'shifumi', name:'Shi-Fu-Mi', checked: true},
        {id: 7, type: 'knowurbros', name:'Tu connais tes potes ?', checked: true},
        {id: 8, type: 'bulletdodge', name:'Esquive les balles', checked: true},
        {id: 9, type: 'fragmentation', name:'Fragmentation', checked: true}
      ]
    }

    console.log("[[", process.env.NODE_ENV ," ENVIRONMENT]] - isProduction = ", isProduction);

    let url = 'http://localhost:8888';
    let secure = false;
    if(isProduction){
      url = "https://drinkwar.idontcare.fr:8888";
      secure = true;
    }
    const socket = io.connect(url, {secure: secure});
    this.props.dispatch(setUserFields({socket: socket}));

    // Ici ce sont tous les events reçus depuis socket.io
    socket.on('connectionOK', this.connectionOK);
    socket.on('createRoomOK', this.createRoomOK);
  }
  
  downloadApp = () => {
    window.open("https://drinkwar.idontcare.fr/app.apk", "_blank")
  }

  componentDidMount(){
    if(this.props.isProduction){
      window.addEventListener("beforeunload", this.onLeavePage);
    }
  }

  connectionOK = (id) => {
    const { room } = this.props
    console.log('connected - ',id);

    //Cas de déconnection ( re-connection mais avec une room déjà locked !)
    // DOESN'T WORK // NEVER TRIGGER :
    if(room.isLocked){
      Swal.fire({
        showCancelButton: false,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Ok',
        showConfirmButton: true,
        allowOutsideClick: false,
        type: 'error',
        title: `Vous avez été déconnecté !`,
        text: "Pour les téléphones android, utilisez plutôt l'application dédiée !"
      }).then((result) => {
        if (result.value) {
          //Reset à la page d'accueil
          this.props.dispatch(setRoomData({}));
        }
      })
    }
  }

  createRoomOK= (room) => {
    this.props.dispatch(setRoomData(room));
  }

  createRoom = () => {
    const { socket, isProduction } = this.props
    if(isProduction){
      Swal.fire({
        title: "Création",
        type: 'info',
        html: `L'appareil qui créer la partie n'est pas un joueur !
                <br>Vous êtes bien sur une TV/Ordi/Projo/Tablette ?
                <br/><br/>
                <div id='settings'>
                  <img  src="${Cogs}" style="width:8vmin; height:8vmin; cursor:pointer"/>
                </div>`,
        showCancelButton: true,
        cancelButtonText: "Annuler",
        confirmButtonText: "Je sais, oui !",
        animation: "slide-from-top",
        onOpen: () => {
          const cogs = document.getElementById('settings');
          cogs.addEventListener('click', this.toggleDrawer);
          cogs.addEventListener('touchestart', this.toggleDrawer);
        }
      }).then((result) => {
        if(result.value){
          const mj = this.state.minijeux.filter(j => !j.checked).map(j => j.id);
          socket.emit('createRoom', {
                                      maxScore: this.state.maxScore,
                                      gamesToDelete: mj,
                                      isDrinkingGame: this.state.isDrinkingGame
                                    } );
          this.props.dispatch(setUserFields({isHost:true}));
        }
      })
    } else {
      socket.emit('createRoom', {maxScore: this.state.maxScore, gamesToDelete: []} );
      this.props.dispatch(setUserFields({isHost:true}));
    }
  }

  setPlayerName= () => {
    Swal.fire({
      title: 'Donne ton nom :',
      input: 'text',
      showCancelButton: false,
      confirmButtonText: 'C\'est moi',
      preConfirm: (name) => {
        if(name.trim() === ''){
          Swal.showValidationMessage("Rentre un nom putain !!");
        }
      },
    }).then((result) => {
      if (result.value) {
        this.joinRoom();
        localStorage.setItem('name', result.value);
        this.props.dispatch(setUserFields({name: result.value}));
      }
    })
  }

  clickJoin = () => {
    const { classes, isProduction } = this.props;

    // Ouvre les modals SWAL pour setter le nom du joueur si on l'a pas déjà
    const localName = localStorage.getItem('name');
    if(localName){
      this.props.dispatch(setUserFields({name: localName}));
      if(isProduction){
        Swal.fire({
          title: `Tout d'abord, est-ce bien toi "${localName}" ?`,
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          reverseButtons: true,
          cancelButtonText: 'Modifier',
          cancelButtonClass: classes.warningColor,
        }).then((result) => {
          if(result.dismiss === Swal.DismissReason.cancel){
            this.setPlayerName();
          } else {
            this.joinRoom();
          }
        });
      } else {
        this.joinRoom();
      }
    } else {
      this.setPlayerName();
    }
  }
  
  promiseJoinRoomWrapper = (trigramme) => {
    return new Promise((resolve, reject) => {
      if(!this.props.socket){
        reject('No socket connection !');
      } else {
        this.props.socket.emit('room.join',
                          trigramme,
                          this.props.name,
                          version,
                          (response) => resolve(response));
      }
    });
 }
 
  joinRoom = () => {
    const { dispatch, isProduction } = this.props;
    Swal.fire({
      title: 'Mot de passe de la partie ?',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Rejoindre',
      cancelButtonText: "Annuler",
      showLoaderOnConfirm: true,
      preConfirm: (trigramme) => {
        // Ici on envoie au serveur le trigramme pour voir si on peut join la room
        // Sauf que SWAL ne marche que avec des promises ( .then().catch() )
        // Donc le 'trick' est d'encapsuler l'appel dans une promise ( créée dans promiseJoinRoomWrapper )
        const input = Swal.getContent().getElementsByClassName('swal2-input')[0];
        if(trigramme.trim().length === 3){
          trigramme = trigramme.trim().toUpperCase();
          return this.promiseJoinRoomWrapper(trigramme)
                    .then((retour) => {
                      console.log(retour);
                      if(retour.isOk){
                        if(!isProduction){
                          Swal.close();
                        } else {
                          Swal.fire({
                            title: `${this.props.name}, bienvenue dans la partie !`,
                            type: 'success'
                          });
                        }
                        dispatch(setUserFields({room:trigramme}));
                        this.activateNoSleep();
                      } else {
                          Swal.showValidationMessage("Erreur : "+retour.error);
                      }
                    });
            
        } else {
          Swal.showValidationMessage("Un mot de passe de partie c'est 3 lettres mon coco !");
          input.value = ''
        }
      },
    })
    const input = Swal.getContent().getElementsByClassName('swal2-input')[0];
    input.addEventListener("input", (e) => this.setInMaj(e));
    input.addEventListener("keyup", (e) => this.setInMaj(e));
  }

  setInMaj = (e) => {
    e.target.value =  e.target.value.toUpperCase();
  }
  
  activateNoSleep = (e) => {
    const { isApp } = this.state;
    if(!isApp){
      var noSleep = new NoSleep();
      noSleep.enable();
    }
  }

  toggleDrawer = () => {
    this.setState({isSettingsOpen: !this.state.isSettingsOpen});
  }

  toggleMinijeux = (i) => {
    const newJeux = this.state.minijeux;
    newJeux[i].checked = !newJeux[i].checked;
    this.setState({minijeux: newJeux});
  }

  render() {
    const { classes, isProduction } = this.props;
    const { isApp, isSettingsOpen, maxScore, minijeux, isDrinkingGame } = this.state;
    const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;

    const toggleButtonsClass = {
      root: classes.root,
      selected: classes.selected
    }
    return (
      <div>
        <div className={classes.title}>Drink War</div>
        <img 
          src={logo}
          alt="logo stylé"
          className={classes.homeLogo}
        />
        <div className={classes.flex}>
          <Button
            variant="contained"
            className={classes.btnHome}
            color="primary"
            onClick={this.createRoom}>
            Créer une partie
          </Button>
          {
            isAndroid && !isApp &&
            <Button
              variant="contained"
              className={classes.btnDownload}
              color="primary"
              onClick={this.downloadApp}>
              <span className={classes.spanForSvg}>
                <SVG
                  src={smartphone}
                  >
                </SVG>
              </span>
            </Button>
          }
        </div>
        <div className={classes.flex}>
          <Button
            variant="contained"
            className={classes.btnHome}
            color="primary"
            onClick={this.clickJoin}>
            Rejoindre une partie
          </Button>
        </div>


        <div className={classes.version}>
          v{version}
        </div>

        <Drawer anchor="top" open={isSettingsOpen} onClose={this.toggleDrawer}>
          <div className={classes.settings}>
              <FormGroup row className={classes.maxScore}>
                <div>
                  <span className={classes.settings}>
                    Score à atteindre :
                  </span>
                  <ToggleButtonGroup value={maxScore} exclusive onChange={(e, v) => v ? this.setState({maxScore:v}) : ''}>
                    <ToggleButton value={10} classes={toggleButtonsClass}>
                      10
                    </ToggleButton>
                    <ToggleButton value={20} classes={toggleButtonsClass}>
                      20
                    </ToggleButton>
                    <ToggleButton value={30} classes={toggleButtonsClass}>
                      30
                    </ToggleButton>
                    <ToggleButton value={40} classes={toggleButtonsClass}>
                      40
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <div className={classes.settings}>
                  <span>
                    Jeu à boire ?
                  </span>
                  <Switch
                    checked={isDrinkingGame}
                    onChange={() => this.setState({isDrinkingGame: !this.state.isDrinkingGame})}
                    value={isDrinkingGame}
                    color="primary"
                  />
                </div>
              </FormGroup>
              <FormGroup row className={classes.minijeux}>
                {
                  minijeux.map((j, i) => 
                    <FormControlLabel
                        key={i}
                        control={
                          <Switch
                            checked={j.checked}
                            onChange={() => this.toggleMinijeux(i)}
                            value={j.checked}
                            color="primary"
                            disabled={isProduction ? j.disabled : false}
                          />
                        }
                        label={j.name}
                      />)
                  }
              </FormGroup>
                
          </div>
        </Drawer>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(HomeStyles)(HomeView));
