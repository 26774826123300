import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import { compose } from 'redux'
import ReactTimeout from 'react-timeout'

import SVG from 'react-inlinesvg';
import cat from '../../img/icons/cat.svg';
import catSited from '../../img/icons/cat_sit.svg';
import catWon from '../../img/icons/cat_won.svg';
import fisherMan from '../../img/icons/fisherman.svg';
import fish from '../../img/icons/fish.svg';
import Utils from '../../utilsES6';

import SoundCat0 from '../../sounds/miaou1.mp3'
import SoundCat1 from '../../sounds/miaou2.mp3'
import SoundCat2 from '../../sounds/miaou3.mp3'
import SoundCat3 from '../../sounds/miaou4.mp3'
import PickAFish from '../../sounds/pickAFish.mp3'
import Ambient from '../../sounds/fishBetAmbiance.mp3'

import FishBetStyles from './FishBetStyles';

function mapStateToProps(state) {
  return {
    game: state.room.game,
    isHost: state.me.isHost,
    socket: state.me.socket,
    isProduction: state.isProduction
  };
}

const Colors = [ '#f2aacd', '#89431e', '#76c4da', '#a3011c'];
const Sounds = [ SoundCat0, SoundCat1, SoundCat2, SoundCat3];
let intervalId;
const INITIAL_STATE = {
  isBetting: true,
  hasBet: false,
  isEnd: false,
  isMiaou: false,
  hasWon: false,
  playerChoice: -1,
  maxStep: 4,
  loteryCatId:2,
  classFishAnimate: '',
  //Ids des chats match les index ! pour simplifier le lien avec le tableau Colors[]
  cats: [
    {id: 0, step: 0, srcCat: catSited, classToAdd: '', name: 'Hello Kitty'},
    {id: 1, step: 0, srcCat: catSited, classToAdd: '', name: 'Thomas O\'Malley'},
    {id: 2, step: 0, srcCat: catSited, classToAdd: '', name: 'Oggy'},
    {id: 3, step: 0, srcCat: catSited, classToAdd: '', name: 'Garfield'},
  ]
}


class FishBetView extends Component {
  constructor(props){
    super(props);
    const { socket = {}, isProduction } = this.props

    //Prevent from modifying the initial state obj
    this.state = JSON.parse(JSON.stringify(INITIAL_STATE));

    if(!isProduction){
      this.state.maxStep = 3;
    }

    // Events reçus depuis socket.io
    socket.on('fishBet.EveryBodyHasBet', this.startTheRace);
  }

  componentWillUnmount(){
    const { socket = {} } = this.props
    socket.removeAllListeners("fishBet.EveryBodyHasBet");
    this.props.clearInterval(intervalId);
    intervalId = null;
  }

  playMiaou = () => {
    const { playerChoice, isMiaou  } = this.state;
    if(playerChoice !== -1 && !isMiaou){
      this.setState({isMiaou: true})
      const audio = new Audio(Sounds[playerChoice]);
      audio.play();
      this.props.setTimeout(() => {
        this.setState({isMiaou: false});
      }, 1500);
    }
  }

  startTheRace = () => {
    const { isHost } = this.props;
    if(isHost){
      this.setState({isBetting: false});

      //Son d'ambiance
      const audio = new Audio(Ambient);
      audio.play();

      this.pickAFish();
    }
  }

  pickAFish = () => {
    const { rand, getById } = Utils;
    const { cats, maxStep } = this.state;
    const { socket } = this.props;

    const winner = this.getWinner();
    if(!winner){

      // Loterie :
      intervalId = this.props.setInterval(() => {
        const nextId = rand(0, Colors.length, [this.state.loteryCatId]);
        this.setState({loteryCatId: nextId});
      }, 200);

      // son de canne à pêche :
      const audio = new Audio(PickAFish);
      audio.play();
      
      this.props.setTimeout(() => {
        //Loterie de 2sc puis on coupe l'interval
        this.props.clearInterval(intervalId);
        intervalId = null;
        const idSelected = rand(0, cats.length);
        let catSelected = getById(cats, idSelected);
        catSelected.step++

        // Plays the cat Sound ( id = index !)
        const audio = new Audio(Sounds[idSelected]);
        audio.play();

        //Met à jour si besoin le svg du chat qu'on va render
        if(catSelected.step > 0)
          catSelected.srcCat = cat;
        if(catSelected.step === maxStep)
          catSelected.srcCat = catWon;

        this.setState({cats: cats, loteryCatId: catSelected.id, classFishAnimate: "animated heartBeat"});

        this.props.setTimeout(() => {
          this.setState({classFishAnimate: ""});
          this.pickAFish();
        }, 1500);

      }, 2000);

    } else {

      this.props.setTimeout(() => {
        winner.classToAdd = "animated bounce";
        this.setState({cats:cats});
      }, 300);

      this.props.setTimeout(() => {
        socket.emit('fishBet.results', winner.id);
      }, 4000);

      //OVER
      //  console.log('======> Winner is '+winner.name)
    }
  }

  getWinner = () => {
    const { cats, maxStep } = this.state;
    let r = false;
    cats.forEach(cat => {
      if(cat.step >= maxStep){
        r = cat;
      }
    });
    return r;
  }

  renderHost = () => {
    const { classes } = this.props;
    const { isBetting, loteryCatId, cats, maxStep, classFishAnimate } = this.state;

    if(isBetting){
      return  <div className={classnames(classes.question, "animated jello slow")}>
                Faîtes vos jeux, rien ne va plus !
              </div>
    }

    return <div className={classes.containerRace}>
              <div className={classes.race}>
                {
                  cats.map((c, index) => {
                    let newMarginLeft = "calc(" + (100/maxStep)*c.step + '% - 16vmin )';
                    if(c.step === 0)
                      newMarginLeft = '0'
                    return  <SVG
                              key={index}
                              src={c.srcCat}
                              className={classnames(classes.iconRace, c.classToAdd)}
                              style={{fill: Colors[c.id], marginLeft: newMarginLeft}}>
                            </SVG>
                  })
                }
              </div>
              <div className={classes.fishAndFisherman}>
                <div className={classes.fisherman}>
                  <SVG src={fisherMan}></SVG>
                </div>
                <div className={classnames(classes.loterieFish, classFishAnimate)}>
                  <SVG
                    src={fish}
                    style={{fill: Colors[loteryCatId]}}>
                  </SVG>
                </div>
              </div>
            </div>
  }

  selectDatCat = (id) => {
    this.setState({playerChoice: id});
  }

  renderPlayer = () => {
    const { getById } = Utils;
    const { classes } = this.props;
    const { hasBet, cats, isMiaou, playerChoice } = this.state;
    if(hasBet){
      let catSelected = getById(cats, playerChoice);
      return <div className={classes.containerChoice}>
              <SVG
                src={cat}
                className={classes.iconSelected}
                style={{fill: Colors[catSelected.id]}}>
              </SVG>
              <div className={classes.containerCatSelectedName}>
                <span className={classes.catSelectedName}>
                  {catSelected.name}
                </span>
              </div>
              <Button 
                variant="contained"
                className={classes.miauler}
                color="secondary"
                onClick={this.playMiaou}>
                {
                  isMiaou
                  ? <i className={classnames(classes.buttonEncourage, "fas fa-volume-up")}></i>
                  : "Encourager"
                }
              </Button>
            </div>
    } else {
      return <div className={classes.container}>
              <div className={classes.catChoices}>
                <span>Quel chat va gagner la course à votre avis ?</span>
                {
                  cats.map((c, index) => {
                    return  (
                              <div key={index} className={classes.catChoice} onClick={() => this.selectDatCat(c.id)}>
                                <SVG
                                  src={cat}
                                  className={classes.icon}
                                  style={{fill: Colors[c.id]}}>
                                </SVG>
                                <span
                                  className={classnames(classes.catName, playerChoice === c.id && classes.selectedName)}>
                                  {c.name}
                                </span>
                                <SVG
                                  src={cat}
                                  className={classnames(classes.icon, classes.inverted)}
                                  style={{fill: Colors[c.id]}}>
                                </SVG>
                              </div>)
                    })
                }
              </div>
              <Button
                variant="contained"
                className={classnames(classes.validate, playerChoice === -1 && classes.hidden)}
                onClick={this.sendChoice}
                >
                Valider
              </Button>
            </div>
    }
  }

  sendChoice = () => {
    const { socket } = this.props
    socket.emit('fishBet.lockTheBet', this.state.playerChoice);
    this.setState({hasBet: true});
  }

  render() {
    const { isHost } = this.props;
    return isHost ? this.renderHost() : this.renderPlayer()
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(FishBetStyles),
  ReactTimeout
)(FishBetView);