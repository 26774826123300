import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import { compose } from 'redux'
import ReactTimeout from 'react-timeout'

import { dark } from '../../core/GlobalStyles'
import WaitingRoomStyles from './WaitingRoomStyles';
import { adminPlayerSelector } from '../GameSelectors';

function mapStateToProps(state) {
  return {
    room: state.room,
    socket: state.me.socket,
    name: state.me.name,
    isHost: state.me.isHost,
    isAdmin: state.me.isAdmin,
    adminPlayer: adminPlayerSelector(state.room.playerList),
    isProduction: state.isProduction
  };
}

let int;

class WaitingRoomView extends Component {
  constructor(props){
    super(props);
    this.state ={
      percent: 0,
      status: 'four',
      clue: false
    };
  }

  startTimer = () => {
    int = this.props.setInterval(() => {
      const nextPercent = this.state.percent + 5;
      this.setState({percent: nextPercent});
      switch(true){
        case (nextPercent > 74 ):
          this.setState({status: 'one'});
          break;
        case (nextPercent > 49 ):
          this.setState({status: 'two'});
          break;
        case (nextPercent > 24 ):
          this.setState({status: 'three'});
          break;
        default:
          this.setState({status: 'four'});
          break; 
      }
      if(nextPercent>100){
        this.props.clearInterval(int);
        int = null;
        this.startGame();
      }
    }, 200);
  }

  stopTimer = () => {
    this.setState({percent: 0, clue: true});
    this.props.clearInterval(int);
    int = null;
  }

  startGame = () => {
    this.props.socket.emit('room.lock');
  }

  renderHost = () => {
    const { adminPlayer, room, classes } = this.props;
    return  <div className={classes.motd}>
              Bienvenue compagnons de boisson,
              <br/> 
              Veuillez prendre place dans cette joute qui se prépare...
              <br/>
              Votre mot de passe pour cette partie sera  
              <br/>
              <span className={classes.secondary}>{room.id}</span>
              <br/>
              <br/>
              <br/>
              {
                adminPlayer &&
                <div>
                  Dans ce perilleux voyage, 
                  <br/>
                  <span className={classes.secondary}>
                    {adminPlayer.name}
                  </span>
                  <br/>
                  sera votre guide
                </div>
              }
            </div>
  }

  renderAdmin = () => {
    const { classes, isProduction } = this.props;
    const { percent, status, clue } = this.state;
    return  <div className={classes.motd}>
              <div className={classes.paddingABit}>
                <Progress
                  width="30vmin"
                  type="circle"
                  symbolClassName={classes.textTimer}
                  percent={percent}
                  status={status}
                  theme={
                    {
                      four: {
                        color: 'red',
                        symbol: '4'
                      },
                      three: {
                        color: '#e28500', // Orange
                        symbol: '3'
                      },
                      two: {
                        color: '#e2dd00e8', // Yellow
                        symbol: '2'
                      },
                      one: {
                        color: '#2dcc24', // Green
                        symbol: '1'
                      },
                      default: {
                        color: dark, // Black --> invisible
                        symbol: ' '
                      }
                    }
                  }
                />
              </div>
              <div className={classes.fontSizeLarge}>
                Une fois la partie lancée plus personne ne peut se connecter !
              </div>
              <div>
                <Button
                  variant="contained"
                  className={classes.buttonGO}
                  color="secondary"

                  onMouseDown={isProduction ? this.startTimer : null}
                  onTouchStart={isProduction ? this.startTimer : null} 
                  onMouseUp={isProduction ? this.stopTimer : null}
                  onTouchEnd={isProduction ? this.stopTimer : null}

                  onClick={isProduction ? null : this.startGame}
                  >
                  Lancer la partie
                </Button>
              </div>
              {
                clue &&
                <div className={classes.marginTop4vmin}>
                  ( Appuie plus longtemps ! )
                </div>
              }
            </div>
  }

  render() {
    const { isHost, isAdmin, classes } = this.props;

    if(isHost){
      return this.renderHost();
    } else if(isAdmin){
      return this.renderAdmin();
    } else {
      return  <div className={classes.motd}>
                <div className={classes.fontSizeLarge}>
                  On attend tes potes...
                </div>
              </div>
    }
  }

}

export default compose(
  connect(mapStateToProps),
  withStyles(WaitingRoomStyles),
  ReactTimeout
)(WaitingRoomView);