import { ellipsis } from '../../core/GlobalStyles'

export default {
  playerImage: {
    width: '10vmin',
    height: '10vmin',
  },
  list:{
    display: 'flex',
    fontSize: '3vmin'
  },
  player:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'none',
    margin: '1.5vw'
  },
  playerName:{
    maxWidth: '20vw',
    marginTop: '5px',
    ...ellipsis
  }
}