/********************************************************************************
 * 
 *                 Toutes les actions de mise à jour local:
 *       Pour un changement de state qui se répercute hors d'un composant par ex
 * 
 *******************************************************************************/ 
export const setUserFields = (payload) => ({
	type: "SET_USER_FIELDS",
  payload: payload
});

export const setRoomData = (room) => ({
	type: "SET_ROOM_DATA",
  payload: room
});

export const updateRoom = (room) => ({
	type: "UPDATE_ROOM",
  payload: room
});

export const updateRoomStatus = (status) => ({
	type: "UPDATE_ROOM_STATUS",
  payload: status
});

export const updatePlayerList = (list) => ({
	type: "UPDATE_PLAYER_LIST",
  payload: list
});

export const updateGame = (game) => ({
	type: "UPDATE_GAME",
  payload: game
});