import { primary, greenSuccess, successButton, medium, hidden } from '../../core/GlobalStyles'

export default {
  checkBoxIcon: {
    width: '10vmin',
    height: '10vmin',
  },
  question: {
    fontSize: '8vmin',
    color: primary,
    fontFamily: 'revalia',
    animationIterationCount: 3
  },

  containerCatSelectedName:{
    margin: '10vmin'
  },
  catSelectedName: {
    fontSize: medium,
    flex: 10,
  },
  iconSelected:{
    width: '20vmin',
    height: '20vmin'
  },
  miauler: {
    fontSize: medium,
    flex: '10',
    width: '50vw'
  },
  containerChoice: {
    display: 'flex',
    height: '50vh',
    width: '90vw',
    flexDirection: 'column',
    alignItems: 'center'
  },

  container: {
    display: 'flex',
    height: '95vh',
    width: '90vw',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  catChoices: {
    display: 'flex',
    alignItems: 'center',
    flex: '90',
    paddingLeft: '4vmin',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  catChoice: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: '12vmin',
    height: '12vmin',
    margin: '0 2vmin'
  },
  validate:{
    ...successButton,
    fontSize: medium,
    display: 'flex',
    flex: '10',
    width: '100%'
  },
  inverted:{
    transform: 'scaleX(-1)'
  },
  catName: {
    fontSize: medium
  },
  selectedName: {
    color: greenSuccess
  },
  ...hidden,



  // CAT RACE :
  containerRace: {
    display: 'flex',
    width: '95vw',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '79vh'
  },
  race:{
    flex: '80', 
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start'
  },
  fishAndFisherman:{
    flex: '20'
  },
  iconRace:{
    width: '12vmin',
    height: '12vmin',
    animationIterationCount: 3,
    '-webkit-transition': '1s',
    '-moz-transition': '1s',
    '-ms-transition': '1s',
    '-o-transition': '1s',
    transition: '1s'
  },
  fisherman: {
    width: '25vmin',
    height: '25vmin',
  },
  loterieFish:{
    position: 'relative',
    top: '-13.2vmin',
    left: '16vmin',
    width: '5vmin',
    height: '5vmin',
    transform: 'rotate(-37deg)'
  }
}