import { primary, successButton, medium, hidden } from '../../core/GlobalStyles'

export default {
  checkBoxIcon: {
    width: '10vmin',
    height: '10vmin',
  },
  container: {
    display: 'flex',
    height: '95vh',
    width: '90vw',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  reponses: {
    display: 'flex',
    flex: '90',
    paddingLeft: '4vmin',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  rep: {
    display: 'flex',
    textAlign: 'left'
  },
  repTexte: {
    color: primary,
    fontFamily: 'mogra',
    fontSize: '6vmin'
  },
  question: {
    fontSize: '8vmin',
    color: primary,
    fontFamily: 'revalia'
  },
  validate:{
    ...successButton,
    fontSize: medium,
    display: 'flex',
    flex: '10'
  },
  ...hidden
}