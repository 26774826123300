import { successButton, medium, hidden, mediumRedTitle, secondary, greenSuccess, blood } from '../../core/GlobalStyles'

export default {
  ...mediumRedTitle,
  checkBoxIcon: {
    width: '8vmin',
    height: '8vmin',
  },
  container: {
    display: 'flex',
    height: '80vh',
    width: '95vw',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  explication:{
    display: 'flex',
    flex: '5',
    alignSelf: 'center'
  },
  answerContainer:{
    display: 'flex',
    flex: '85',
    width: '100%',
    justifyContent: 'space-evenly'
  },
  flexColumn:{
    flexDirection: 'column !important'
  },
  rep: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    '& label':{
      margin: '0'
    }
  },
  repTexte: {
    color: 'white',
    fontFamily: 'mogra',
    fontSize: '5vmin'
  },
  question: {
    fontSize: '6vmin',
    color: secondary,
    fontFamily: 'revalia'
  },
  playerName:{
    display: 'flex',
    alignItems: 'center',
    fontSize: '7vmin'
  },
  validate:{
    ...successButton,
    fontSize: medium,
    display: 'flex',
    flex: '10'
  },
  ok: {
    color: greenSuccess
  },
  ko:{
    color: blood
  },
  ...hidden
}