/*************************************************************
 *                          COLORS
 *************************************************************/

export const blood = '#9a0303';
export const dark = '#0c0c0d';
export const beer = '#e6a730';
export const greenSuccess = '#5cb85c'
export const blue = '#198eac';

export const primary = blood;
export const secondary = beer;

/*************************************************************
 *                          Sizes
 *************************************************************/

export const small = '2vmin';
export const medium = '5vmin';
export const large = '10vmin';
export const xlarge = '15vmin';


/*************************************************************
 *                          Utils
 *************************************************************/

export const successButton = {
  backgroundColor: greenSuccess,
  color: 'white',
  fontFamily: 'mogra',
  '&:hover, &:active':{
    backgroundColor: '#449c44',
    color: '#e6e6e6'
  }
};

export const ellipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}
;
export const blurry = {
    color: 'transparent',
    textShadow: '0 0 5px rgba(0,0,0,0.5)'
}
/**     
 * better like : gameView * {
 *                filter: blur(3px)
 *             }
 */


 

// Export des classes elles-même :
// plus qu'a utilser avec ...class pour l'avoir dans le composant 
export const hidden = {
  hidden: {
    visibility: 'hidden'
  }
};
export const noScrollBar = {
  noScrollBar:{
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar':{
      display: 'none'
    }
  }
}
export const bigRedTitle = {
  bigRedTitle: {
    fontFamily: 'revalia',
    fontSize: large,
    color: blood
  }
};
export const mediumRedTitle = {
  mediumRedTitle: {
    fontFamily: 'revalia',
    fontSize: medium,
    color: blood
  }
};

