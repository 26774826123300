import { mediumRedTitle, blood, greenSuccess, hidden } from '../../core/GlobalStyles'

export default {
  ...mediumRedTitle,
  containerPlayer: {
    height: '95%',
    width: '90vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column'
  },
  containerHost:{
    display: 'flex',
    flex: '100',
    overflow:'hidden',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar':{
      display: 'none'
    }
  },
  containerFight:{
    display: 'flex',
    flex: '100',
    height: '70vh',
    alignItems: 'center',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar':{
      display: 'none'
    }
  },
  round:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',
    height: '80vh',
    padding: '0px 4vmin',
    animationIterationCount: 3,
  },
  match:{
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',
  },
  vs:{
    color: blood,
    fontSize: 'calc(3px + 2vmin);'
  },
  hr:{
    width: '100%'
  },
  winner:{
    color: greenSuccess
  },
  looser:{
    color:'#5a5a5a'
  },
  tileContainer:{
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  tile:{
    width: '20vmin',
  },
  textAlignCenter:{
    textAlign: 'center'
  },
  popUpContainer:{
    display: 'flex',
    width: '100%',
  },
  popUpContent:{
    display: 'flex',
    flex: '30',
    flexDirection: 'column',
    alignItems: 'center'
  },
  svgPopUpDiv:{
    width: '20vmin',
    height: '20vmin',
  },
  svgFight:{
    width: '30vmin',
    height: '30vmin',
  },
  mogra:{
    fontFamily: 'mogra'
  },
  ...hidden

}