import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import SVG from 'react-inlinesvg';
import { compose } from 'redux'
import ReactTimeout from 'react-timeout'

import Swal from '../../core/SwalLoader';
import TimerView from '../timer/TimerView'

import Wine from '../../img/memory/wine.svg'
import Whisky from '../../img/memory/whisky.svg'
import Vomit from '../../img/memory/vomit.svg'
import Tequila from '../../img/memory/tequila.svg'
import Cocktail1 from '../../img/memory/cocktail1.svg'
import Cocktail2 from '../../img/memory/cocktail2.svg'
import Beer from '../../img/memory/beer.svg'
import Barrel from '../../img/memory/barrel.svg'

import Alarm from '../../img/icons/alarm-clock.svg'

import Gulp1 from '../../sounds/gulp1.mp3'
import Gulp2 from '../../sounds/gulp2.mp3'
import Gulp3 from '../../sounds/gulp3.mp3'
import Gulp4 from '../../sounds/gulp4.mp3'
import Gulp5 from '../../sounds/gulp5.mp3'
import newSequence from '../../sounds/newSequence.mp3'

import TimesUpMemory from '../../sounds/timesUpMemory.mp3'

import loading from '../../img/loading.gif';
import Utils from '../../utilsES6';

import MemoryStyles from './MemoryStyles';

const Gulps = [Gulp1, Gulp2, Gulp3, Gulp4, Gulp5];
let t;

function mapStateToProps(state) {
  return {
    game: state.room.game,
    isHost: state.me.isHost,
    socket: state.me.socket,
    playerList: state.room.playerList
  };
}

class MemoryView extends Component {
  constructor(props){
    super(props);
    const { socket = {}, isHost } = this.props
    this.state = {
      isTitle: true,
      isOver: false,
      haveLost: false,
      playingSequence: true,
      sequence: [],
      indexSequence: 0,
      life: 1,
      currentLevel: 0,
      memoryTiles: [
        {id: 0, src: Wine},
        {id: 1, src: Whisky},
        {id: 2, src: Vomit},
        {id: 3, src: Cocktail1},
        {id: 4, src: Tequila},
        {id: 5, src: Beer},
        {id: 6, src: Cocktail2},
        {id: 7, src: Barrel}
      ],
    }

    // Events reçus depuis socket.io
    if(isHost){
      socket.on('memory.generateANewOne', this.generateANewOne);
    } else {
      socket.on('memory.newSequenceReceived', this.setTheSequence);
      socket.on('memory.youHaveLost', this.youHaveLost);
    }
    socket.on('memory.timesUP', this.timesUp);
    socket.on('memory.winnerIs', this.handleWinner);
  }

  componentWillUnmount = () => {
    const { socket = {} } = this.props
    socket.removeAllListeners("memory.generateANewOne");
    socket.removeAllListeners("memory.newSequenceReceived");
    socket.removeAllListeners("memory.timesUP");
    socket.removeAllListeners("memory.winnerIs");
    this.props.clearTimeout(t);
    t = null;
  }

  componentDidMount = () => {
    const { isHost } = this.props;
    
    this.props.setTimeout(() => {
      this.setState({isTitle: false});
     
      if(isHost){
        this.props.setTimeout(() => {
          this.generateSequence(0);
        }, 1200);
      }
    }, 4000);
  }

  youHaveLost = () => {
    this.setState({life:0});
    this.youLoose(false);
  }

  handleWinner = (idWinner) => {
    const { socket = {}, playerList, isHost } = this.props
    const {  getById } = Utils;
    this.props.clearTimeout(t);
    t = null;
    if(idWinner === 'nobody'){
      Swal.fire({
        showConfirmButton: false,
        allowOutsideClick: false,
        type: 'error',
        title: 'Woaw !',
        text: 'Tout le monde a perdu !'
      });
    } else if(socket.id === idWinner){
      Swal.fire({
        showConfirmButton: false,
        allowOutsideClick: false,
        type: 'success',
        title: 'BRAVO !',
        text: 'Tu as gagné !'
      });
    } else if(isHost) {
      const winner = getById(playerList, idWinner);
      Swal.fire({
        showConfirmButton: false,
        allowOutsideClick: false,
        type: 'success',
        title: 'Fini',
        text: `Et c'est ${winner.name} qui a gagné !`
      });
    } else {
      Swal.fire({
        showConfirmButton: false,
        allowOutsideClick: false,
        type: 'error',
        title: 'Désolé...',
        text: 'mais alzeihmer te guette !'
      });
    }
    this.props.setTimeout(() => {
      this.setState({isOver: true});
      Swal.close();
    }, 4500);
  }

  timesUp = (winnerId) => {
    const { isHost } = this.props
    this.unMountTimer();
    this.props.clearTimeout(t);
    t = null;
    if(isHost){
      const audio = new Audio(TimesUpMemory);
      audio.play();
      Swal.fire({
        showConfirmButton: false,
        allowOutsideClick: false,
        title: 'Time\'s up !',
        html: `<img src="${Alarm}" 
                style="width:20vmin; height:20vmin" 
                class="animated swing" />`
      });
      this.props.setTimeout(() => {
        this.props.clearTimeout(t);
        t = null;
        if(winnerId){
          this.handleWinner(winnerId);
        } else {
          this.generateANewOne();
        }
      }, 3000);
    } else if(winnerId) {
      this.props.setTimeout(() => {
        this.props.clearTimeout(t);
        t = null;
        this.handleWinner(winnerId);
      }, 3000);
    }
  }

  setTheSequence = (sequence) => {
    this.setState({sequence: sequence, indexSequence:0, playingSequence: false});
  }

  generateANewOne = () => {
    const nextLevel = this.state.currentLevel + 1;
    this.setState({currentLevel: nextLevel});
    this.generateSequence(nextLevel);
  }

  generateSequence = (level) => {
    const { memoryTiles } = this.state;
    const { rand, shuffle } = Utils;
    let nbTiles = level + 3;
    let isShuffle = false;

    switch (true) {
      case level === 0:
        Swal.fire({
          showConfirmButton: false,
          allowOutsideClick: false,
          title: 'Observez bien la séquence suivante ...',
        });
        break;
      case level <= 3:
        Swal.fire({
          showConfirmButton: false,
          allowOutsideClick: false,
          title: 'Nouvelle séquence !',
        });
        break;
      case level > 3:
        Swal.fire({
          showConfirmButton: false,
          allowOutsideClick: false,
          title: 'Et si on compliquait un peu ?',
        });
        nbTiles = Math.min(level, 7);
        isShuffle = true;
        break;
      default:
        break;
    }
    //NbTile : +S = Shuffle
    // 3, 4, 5, 6, 4+S, 5+S, 6+S, 7+S, 7+S, 7+S, 7+S...

    let sequence = [];
    for (let i = 0; i < nbTiles; i++) {
      const r = rand(0, memoryTiles.length, sequence.slice(-3));
      sequence.push(r);
    }
    this.setState({sequence: sequence});

    this.props.setTimeout(() => {
      Swal.close();
      if(isShuffle){
        shuffle(memoryTiles);
        this.setState({memoryTiles: memoryTiles});
      }

      const audio = new Audio(newSequence);
      audio.play();

      this.props.setTimeout(() => {
        this.playSequence(0);
      }, 2100);
    }, 2500);
  }

  unMountTimer = () => {
    this.setState({isTimerMounted:false});
  }
  
  playSequence = (index) => {
    const { socket = {}, classes } = this.props;
    const { sequence } = this.state;
    if(sequence.length === index){
      // 1,5 sc par image (x2 si erreur (life)) + 4sc
      const timeForSequence = sequence.length * 1500 * 2 + 4000;
      this.setState({isTimerMounted:true});
      Swal.fire({
        showConfirmButton: false,
        allowOutsideClick: false,
        title: 'À votre tour !',
        html: <div className={classes.textAlignCenter}>
                { 
                  this.state.isTimerMounted && 
                    <TimerView 
                      time={timeForSequence}
                      endOfTimer={this.unMountTimer}
                    />
                }
              </div>
        
      });
      socket.emit('memory.newSequence', sequence, timeForSequence);
    } else {
      this.animateTile(sequence[index]);
      this.props.setTimeout(() => {
        this.playSequence(index+1);
      }, 1000);
    }
  }

  animateTile = (id) => {
    const { memoryTiles } = this.state;
    const { getById, rand } = Utils;
    const currentTile = getById(memoryTiles, id);
    currentTile.currentClass = 'animated bounceIn fast';
    this.setState({memoryTiles: memoryTiles});

    const audio = new Audio(Gulps[rand(0, Gulps.length)]);
    audio.play();
    
    this.props.setTimeout(() => {
      currentTile.currentClass = '';
      this.setState({memoryTiles: memoryTiles});
    }, 800);
  }
 
  clickOnTile = (id) => {
    const { socket = {} } = this.props;
    const { sequence, indexSequence } = this.state;

    this.animateTile(id);

    if(!this.isNextTile(id)){
        this.youLoose(true);
    } else {
      if(sequence.length-1 === indexSequence){
        Swal.fire({
          showConfirmButton: false,
          allowOutsideClick: true,
          type: 'success',
          title: 'Bravo',
          text: "Tu as réussi cette séquence !"
        });
        socket.emit('memory.AmIStillAlive', true);
        this.setState({playingSequence: true})
        this.props.setTimeout(() => {
          this.props.clearTimeout(t);
          t = null;
          Swal.close();
        }, 3000);
      }
    }
  }

  youLoose = (shouldEmit) => {
    const { socket = {} } = this.props;
    const { life } = this.state;
    if(life){
      Swal.fire({
        showConfirmButton: true,
        allowOutsideClick: true,
        type: 'warning',
        title: 'LOUPÉ !',
        text: "Dernière chance : recommence au DÉBUT de la séquence"
      });
      this.setState({life:0, indexSequence: 0});
      this.props.setTimeout(() => {
        this.props.clearTimeout(t);
        t = null;
        Swal.close();
      }, 2000);
    } else {
      Swal.fire({
        showConfirmButton: false,
        allowOutsideClick: false,
        type: 'error',
        title: 'PERDU',
        text: "Tu es éliminé(e) !"
      });
      this.setState({haveLost: true});
      if(shouldEmit){
        socket.emit('memory.AmIStillAlive', false); 
      }
      this.props.setTimeout(() => {
        this.props.clearTimeout(t);
        t = null;
        Swal.close();
      }, 4000);
    }
  }

  isNextTile = (id) => {
    const { sequence, indexSequence } = this.state;
    if(sequence[indexSequence] === id){
      this.setState({indexSequence: this.state.indexSequence + 1});
      return true;
    } else {
      return false;
    }
  }

  renderPlayer = () => {
    const { classes } = this.props;
    const { isOver, isTitle, playingSequence, memoryTiles, haveLost } = this.state;

    if(isOver || isTitle || playingSequence){
      return <div>
              <img src={loading} alt='Loading gif'/>
            </div>
    } else if(haveLost){
      return <div className={classes.mediumRedTitle}>
                PERDU
            </div>
    } else {
      return <div className={classes.container}>
               {
                  memoryTiles.map((tile, index) => {
                    return  <div
                                onClick={() => this.clickOnTile(tile.id)}
                                key={index}
                                className={classes.tileContainer}>
                              <div
                                className={classnames(classes.tile, tile.currentClass)}>
                                <SVG
                                  src={tile.src}
                                >
                                </SVG>
                              </div>
                            </div>
                  })
                }
            </div>
    }
  }

  renderHost = () => {
    const { classes } = this.props;
    const { isTitle, memoryTiles } = this.state;

    if(isTitle){
      return <div className={classes.mediumRedTitle}>
                C'est l'heure du mémory !
                <br/>
                Qui aura la meilleure mémoire ?
             </div>
    }

    return <div className={classes.container}>
              {
                memoryTiles.map((tile, index) => {
                  return  <div
                              key={index}
                              className={classes.tileContainer}>
                            <div
                              className={classnames(classes.tile, tile.currentClass)}>
                              <SVG
                                src={tile.src}
                              >
                              </SVG>
                            </div>
                          </div>
                })
              }
          </div>
  }


  render() {
    const { isHost } = this.props;
    return isHost ? this.renderHost() : this.renderPlayer();
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(MemoryStyles),
  ReactTimeout
)(MemoryView);