import { secondary, medium } from '../core/GlobalStyles'

export default {
  secondary: {
    color: secondary,
    fontSize: medium,
    fontFamily: 'revalia',
  },
  game: {
    display: 'flex',
    maxHeight: '100vh',
    minHeight: '100vh',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  main:{
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '95%'
  },
  playerList:{
    width: '100%'
  },
  fullScreen:{
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}