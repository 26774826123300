import { mediumRedTitle, medium, secondary, dark, blood, hidden } from '../../core/GlobalStyles'

const bonusStyle = {
  justifyContent: 'center',
  display: 'flex',
  width: '100%',
  border: '5px solid '+ secondary,
  borderRadius: '7px',
  padding: '7vmin 0',
  margin: '10px',
  backgroundColor: dark
}

export default {
  ...mediumRedTitle,
  ...hidden,
  container: {
    display: 'flex',
    height: '100vh',
    width: '95vw',
  },
  selectorContainer:{
    display: 'flex',
    flex:'20',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selector:{
    height: '10vmin',
    width: '10vmin',
    transform: 'rotate(27deg)'
  },
  selectorReversed:{
    transform: 'rotate(-117deg) !important'
  },
  bonusContainer: {
    display: 'flex',
    flex:'60',
    height: '80vh',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: '5vh',
    paddingTop: '11vh'
  },
  bonus:{
    ...bonusStyle
  },
  selectedOne:{
    ...bonusStyle,
    borderColor: blood
  },
  lever:{
    height: '40vmin',
    width: '40vmin',
  },
  leverReversed:{
    transform: 'scaleX(-1)',
  },
  btnNext:{
    maxWidth: '90%',
    width: '90%',
    height: '14vh',
    margin: '3vh 1vh',
    fontFamily: 'mogra',
    fontSize: medium,
    paddingTop: '1vh'
  },
}