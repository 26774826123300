import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AppStyles from './AppStyles';
import HomeView from './home/HomeView'
import GameView from './game/GameView'

function mapStateToProps(state) {
  return {
    name: state.me.name,
    room: state.room.id,
    isProduction: state.isProduction
  };
}

class App extends Component {
  constructor(props){
    super(props);
    const { isProduction } = this.props

    // Prevent the contextual menu to pops-out on mobile devices
    // and btw right click too ( so only on isProd flag !)
    if(isProduction){
      window.oncontextmenu = function(event) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      };
    }
    
  }

  render() {
    const { room, classes = {} } = this.props;
    return (
      <div className={classes.app}>
        { !room ?
            <HomeView></HomeView>
        
        : <GameView></GameView>
        }
          
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(AppStyles)(App));

