import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Progress } from 'react-sweet-progress';
import { dark } from '../../core/GlobalStyles'
import ReactTimeout from 'react-timeout'

import TimerStyles from './TimerStyles';

class TimerView extends Component {
  constructor(props){
    super(props);
    //Set default value if we don't give them to the component ( 10s default )
    const { time=100000 } = this.props;
    this.updateInterval = this.updateInterval.bind(this);
    
    const interval = this.props.setInterval(this.updateInterval, 200);
    this.state = {
      totalTime: time,
      timeRemaining: time,
      percent: 0,
      interval: interval,
      status: 'currentTheme',
      theme: {
        default: {
          color: dark, // --> invisible
          symbol: parseFloat(time/1000).toFixed(1)
        }
      }
    }
  }

  componentWillUnmount = () => {
    this.props.clearInterval(this.state.interval);
  }

  updateInterval = () => {
    const { totalTime, timeRemaining, theme, interval } = this.state;
    // Update every 'refresh'ms ( --> default = 200ms )
    const { refresh=200, endOfTimer } = this.props
    const nextTimeRemaining = timeRemaining - refresh;
    const nextPercent = 100 - Math.round(nextTimeRemaining / totalTime * 100);
    
    // 130 en H de HSLA = vert ( on veut pas du % mais du pour130 ....)
    const nextHSLA = nextTimeRemaining / totalTime*130; 
    let nextSymbol = parseFloat(nextTimeRemaining/1000).toFixed(1);
    
    if(nextPercent>=100){
      nextSymbol = "0.0";
    }

    //Object theme à passer à la lib 'react-sweet-progress'
    // https://github.com/abraztsov/react-sweet-progress#exotic-theme-
    const nextTheme = {
      ...theme,
      currentTheme: {
        color: `hsla(${nextHSLA}, 90%, 36%)`,
        symbol: nextSymbol
      }
    }
    this.setState({
      timeRemaining:nextTimeRemaining,
      theme: nextTheme,
      percent: nextPercent
    });

    if(nextPercent>100){
      this.props.clearInterval(interval);
      this.setState({interval: null});
      endOfTimer();
    }
  }

  render() {
    const { classes } = this.props;
    const { percent, status, theme } = this.state;
    return (
      <div className={classes.marginTimer}>
        <Progress
          width="30vmin"
          type="circle"
          symbolClassName={classes.textTimer}
          percent={percent}
          status={status}
          theme={theme}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(TimerStyles),
  ReactTimeout
)(TimerView);
